.no-items-text {
  display: flex;
  justify-content: center;
  margin: auto !important;
  margin-top: 2em !important;
}

updateobjectcta {
  width: 100%;
  display: flex;
  justify-content: center;
}

.loading-spinner {
  width: auto;
  height: 40px;
  margin: auto;
  display: flex;
  margin-top: 1em;
}

.paragraph-text {
  color: #343741;
  font-size: 16px !important;
}

.claims-select {
  max-width: 350px;
}

claimquestions {
  display: inline-block;
  margin-left: 15px;
  min-width: 350px;
}

.lms-CircularProgressSpinner:focus {
  outline: none;
}

.iaportals-nowdemo-home-start #root {
  background-color: #f5f5f5 !important;
}

.centered-loading-spinner {
  margin: auto;
}

.scChromeToolbar {
  z-index: 901 !important;
}

.on-page-editor .scInsertionHandle {
  z-index: 9100 !important;
}
