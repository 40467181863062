$triangle-filter-alignment: 220px;
recentactivities {
  width: 100%;
}
.filter-dropdown {
  cursor: pointer;
}

.filterDropdownContents {
  position: absolute;
  width: 350px;
  height: 280px;
  padding: 0px;
  background: #ffffff;
  border: #000 solid 1px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: 15px;
  margin-right: 8px;
  z-index: 50;

  .lm-FieldGroup-wrapper {
    margin-left: 11px;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .lm-FieldGroup-wrapper:first-child {
    margin-top: 32px;
  }
}

*::-ms-backdrop,
.filterDropdownContents {
  margin-left: -356px;
}

.filterDropdownContents:before {
  content: '';
  position: absolute;
  top: -26px;
  left: $triangle-filter-alignment;
  border-style: solid;
  border-width: 0px 26px 26px 26px;
  border-color: #000 transparent;
  display: block;
  width: 0;
  z-index: 0;
}

.filterDropdownContents:after {
  content: '';
  position: absolute;
  top: -25px;
  left: $triangle-filter-alignment;
  border-style: solid;
  border-width: 0px 26px 26px 26px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
}

.export-icon {
  margin-left: 5px;
  margin-right: 10px;
}

.filter-dropdown-icon {
  margin-left: 5px;
}

.filterContainer {
  display: flex;
  justify-content: flex-end;
}

.filterLinksAndButtonsRow {
  display: flex;
  justify-content: space-between;
  .filterButtonsContainer button {
    margin-top: 1rem;
    margin-left: 1rem;
  }
}

.filterLinkContainer {
  margin-top: 20px;
  margin-right: 20px;
}

.unsortedColumnHeader {
  cursor: pointer;
  box-shadow: none;
  font-weight: 400;
}

.sortedColumnHeader {
  cursor: pointer;
  box-shadow: 'rgb(6 116 140) 0px -3px 0px 0px inset';
}

.sortIconDown {
  vertical-align: baseline;
  margin-left: 5px;
}

.sortIconUp {
  vertical-align: bottom;
  margin-left: 5px;
}

.lm-Table-row td a {
  font-size: 16px;
}

.table-controls {
  display: flex;
  margin-left: auto;
  padding-right: 1em;
}

#billing-ra-table {
  [data-header='insuredName'] {
    min-width: 250px;
  }
  [data-header='activityType'] {
    min-width: 150px;
  }
  [data-header='activityDate'] {
    min-width: 200px;
  }
  [data-header='minimumDue'] {
    min-width: 200px;
  }
  [data-header='agencyName'] {
    min-width: 200px;
  }
  [data-header='agencyCode'] {
    min-width: 200px;
  }
}

#claims-ra-table {
  [data-header='insuredName'] {
    min-width: 250px;
  }
  [data-header='claimsNumber'] {
    min-width: 200px;
  }
  [data-header='activityDate'] {
    min-width: 200px;
  }
  [data-header='agencyName'] {
    min-width: 200px;
  }
  [data-header='agencyCode'] {
    min-width: 200px;
  }
}

#policy-ra-table {
  [data-header='insuredName'] {
    min-width: 250px;
  }
  [data-header='activityType'] {
    min-width: 200px;
  }
  [data-header='activityDate'] {
    min-width: 200px;
  }
  [data-header='pages'] {
    min-width: 100px;
  }
  [data-header='agencyName'] {
    min-width: 200px;
  }
  [data-header='agencyCode'] {
    min-width: 200px;
  }
}

#recent-activities-form {
  margin: 0;
}

#recent-activities-button {
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-left: 0;
}

#recent-activities-fieldgroup {
  margin-top: 0;

  .lm-FieldGroup-label {
    margin-bottom: 0.5rem;
  }
  .lm-FieldGroupMessages {
    margin-top: 0;
  }
}

recentactivities .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1.5rem !important;
  width: 100%;

  button {
    outline: none !important;
  }

  span {
    align-items: center;
    justify-content: flex-start;
    display: flex;

    &:last-child button.active {
      margin-right: 1rem;
    }

    button {
      &.num {
        display: flex;
        align-items: center;
        margin: 2.5px;
        padding: 12.5px;
        background: none;
        border: none !important;
        color: #343741;

        &:hover {
          cursor: pointer;
        }

        &.active {
          background: #f5f5f5 !important;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          border-radius: 100%;
          outline: 5px auto -webkit-focus-ring-color !important;
        }
      }
    }
  }

  .lm-IconButton::after {
    background: none;
  }

  .lm-IconButton--white:before {
    background: transparent;
  }
}
