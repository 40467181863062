.manage-my-favorites {
  padding-bottom: 64px;
  .instructional-text {
    padding-bottom: 32px;
  }

  .favorites-link {
    max-width: 100%;
  }
  .water-line-message {
    color: #919191;
    text-align: center;
    border-top: 1px solid #e6e6e6;
    font-style: italic;
    padding-top: 8px;
    margin: 12px 0px 24px 0px;
  }

  .other-favorites .menu-section-link {
    margin-top: 8px;
  }

  .manage-my-favorites-links {
    padding-left: 24px;

    a:hover {
      color: #06748c;
    }

    .dropable {
      border: 1px solid #06748c;
    }

    .menu-section-link {
      display: flex;
      align-items: center;
      padding-top: 8px;

      &:hover {
        background-color: #f2fcfc;

        .lm-Icon-Drag path {
          fill: #06748c;
        }
      }

      .favorites-link {
        .lm-Icon-RatingsStarFilled {
          padding-top: 2px;
        }
      }

      .lm-Icon-Drag {
        margin-right: 15px;
      }

      &:hover {
        cursor: grab;
      }

      &:active {
        cursor: grabbing;
      }
    }
  }
}
