@import '~@lmig/lmds-tokens/dist/scss/core/color';

newsarticletitle {
  width: 100%;

  .news-title-container {
    display: inline-flex;
    align-items: center;
    padding-left: 15px;

    .lm-IconButton {
      svg {
        top: 12px;
      }

      &:after {
        background-color: $lmig-color-palette-libertyYellow;
        border: none;
        height: 56px;
        width: 56px;
      }
    }

    .publish-date {
      margin-left: 16px;
    }
  }
}

.news-standard-page {
  .no-margin {
    .safeco-design-system-rich-text {
      margin: 0px !important;
    }

    .rich-text {
      margin: 0px !important;
    }
  }

  .no-padding {
    .safeco-design-system-rich-text {
      padding: 0px !important;
    }

    .rich-text {
      padding: 0px !important;
    }
  }
}
