horizontalcreditperk {
  width: 100%;
  text-align: center;
  h5 {
    font-size: 20px;
  }
  p,
  h5 {
    margin: 0px;
  }

  .credit-perk-info {
    display: flex;
    flex-flow: row wrap;
  }

  .horizontal-credit-perk-container {
    max-width: 800px;
    background-color: #f5f5f5;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 1rem;

    .horizontal-credit-perk-content {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-left: 16%;
      justify-content: space-between;

      .horizontal-credit-perk-text {
        width: 430px;
        margin: 30px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .lmig-Button {
        margin: 35px 35px 35px 0px;
      }
    }
  }

  .horizontal-credit-perk-container-one-third {
    height: 256px;
    width: 396px;
    background-color: #f5f5f5;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 1rem;

    .horizontal-credit-perk-content {
      display: flex;
      flex-flow: column;
      justify-content: center; // vertical

      .horizontal-credit-perk-text {
        margin-top: 125px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        font-family: Roboto, Arial, sans-serif;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      .horizontal-credit-perk-button {
        margin-top: 10px;
      }
    }
  }
}
