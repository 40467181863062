.flexible-content-tile-container {
  display: flex;
  .tile-child:last-child {
    flex-grow: 1;
  }
}

@media only screen and (max-width: 1000px) {
  .portal-tile-v2 {
    height: 100%;
  }
  .flexible-content-tile-container {
    flex-wrap: wrap;
  }
}

.flexcontenttile-first-child-container {
  position: relative;
}
.no-data-tile {
  > div {
    height: 286px;
  }
  > div:first-child {
    width: 66.66%;
    border-right: 1px solid #e6e6e6;
    margin-bottom: 16px;
    padding-right: 16px;
  }
  > div:last-child {
    padding-left: 16px;
    width: 33.33%;
  }
}
