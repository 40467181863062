@import 'Styles/GlobalVariables';

.claim-tab-content {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
  justify-content: space-evenly;
  .claim-tab-column {
    display: flex;
    margin-right: auto;
    .claim-totals-column {
      width: min-content;
    }
    .claim-labels-column {
      display: flex;
      justify-content: space-around;
      padding-top: 8px;
      flex-direction: column;
    }
    .two-item-column {
      padding-top: 15px;
      height: 125px;
    }
  }
}

.claim-large-loss {
  display: flex;
  align-items: baseline;
}

.claim-total-text {
  font-weight: 300;
  font-size: 32px;
  text-align: end;
  line-height: 2em;
  align-items: start;
  padding-left: 16px;
}

.claim-type-label {
  font-size: 16px;
  padding-left: 12px;
}

.large-loss-reserve-container {
  display: flex;
  flex-direction: row;
  align-items: end;
}

.large-loss-reserve-content {
  display: flex;
  padding-bottom: 3px;
}

.lm-FieldGroup-field--radio .lm-FieldOption-input[type='radio'] + .lm-FieldOption-tick:before {
  background-color: #f5f5f5 !important;
}

.claims-radiogroup-container .lm-FieldGroup-inner {
  display: flex;
  justify-content: space-between;
}

.claims-radiogroup-col {
  padding-bottom: 12px;
}

.claims-radiogroup-col {
  padding-top: 12px;
}

.claims-radiogroup-wrapper {
  border-top: 1px solid #e6e6e6;
  padding-bottom: 40px;
}

@media screen and (max-width: 479px) {
  .claim-total-text {
    padding-left: 0px;
    text-align: end;
  }

  .icon-column {
    display: none;
  }

  .claim-type-label {
    padding-left: 0px;
  }

  .claims-radiogroup-wrapper {
    padding-top: 45px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .claims-radiogroup-container {
    margin-left: 0px;
  }
}
