@use 'sass:list';
@use 'sass:map';
@use 'sass:color';
@import '../../styles/foundations';
@import './style/themes/dark';
@import './style/floatingHeaderOverlay';
@import './style/floatingHeaderItem';

%lmig-FloatingHeader--flexRow {
  display: flex;
  flex-direction: row;
}

@mixin lmig-FloatingHeader-menu-style {
  margin-left: $lmig-spacing-x2;

  &-menu {
    display: flex;
    gap: $lmig-spacing-x2;
  }

  &-menu,
  &-subMenu {
    padding: 0;
    margin: 0;
  }
}

.lm-Header.has-FloatingHeader-nav .lm-Masthead {
  display: flex;

  .lmig-FloatingHeader-nav {
    backdrop-filter: none;
    display: none;

    .lmig-FloatingHeader-nav-menu {
      flex-direction: row;
      gap: 2rem;

      .lmig-FloatingHeader-item {
        .lm-Link {
          --lmig-Link-font-color: #{$lmig-color-libertyBlue};
        }
      }
    }
  }

  .lmig-FloatingHeader-actions-menu {
    justify-content: flex-end;
  }

  @include breakpoint-sm {
    .lmig-FloatingHeader-nav {
      display: flex;
    }
  }
}

.lmig-FloatingHeader {
  --lmig-FloatingHeader-height: 72px;
  --lmig-FloatingHeader-margin-top: #{($lmig-spacing-default * 0.5)};
  --lmig-FloatingHeader-font-color: #{$lmig-color-grayDark};
  --lmig-FloatingHeader-item-line-height: var(--lmig-FloatingHeader-height);
  --lmig-FloatingHeader-item--active-background-color: #{$lmig-color-tealDark};

  // default is Liberty brand
  --lmig-FloatingHeader-box-shadow: 0 8px 12px -6px rgba(0, 0, 0, 0.04), 0 6px 4px -1px rgba(0, 0, 0, 0.04),
    0 4px 6px -2px rgba(0, 0, 0, 0.08);
  --lmig-FloatingHeader-brand-color: #{$lmig-color-libertyYellow};
  --lmig-FloatingHeader-background-color: color.change($lmig-color-white, $alpha: 0.7);
  --lmig-FloatingHeader-vertical-menu-background-color: #{$lmig-color-white};

  @include lmig-base-font;
  @include lmig-box-sizing;

  max-width: 100%;
  margin: var(--lmig-FloatingHeader-margin-top) 0 0;
  position: relative;
  z-index: $lmig-layer-menuDropdown-default;

  // Modifiers
  &--isSticky {
    position: sticky;
    top: var(--lmig-FloatingHeader-margin-top);
  }

  &--bg-full {
    --lmig-FloatingHeader-background-color: var(--lmig-FloatingHeader-brand-color);
    --lmig-FloatingHeader-item--active-background-color: #{$lmig-color-libertyBlue};

    &.lmig-FloatingHeader--safeco {
      --lmig-FloatingHeader-font-color: #{$lmig-color-white};
      --lmig-FloatingHeader-item--active-background-color: #{$lmig-color-white};

      .lmig-FloatingHeader-nav-menu {
        .lmig-FloatingHeader-item {
          > .lm-Link {
            --lmig-Link-font-color: #{$lmig-color-white};
          }
        }
      }

      .lmig-FloatingHeader-actions-menu {
        .lm-IconButton {
          --lmig-IconButton-font-color: #{$lmig-color-white};
          --lmig-IconButton-icon-fill-color: #{$lmig-color-libertyBlue};

          .lm-IconWrapper {
            .lm-Icon-label {
              color: var(--lmig-IconButton-font-color);
            }
          }

          &:hover,
          &:focus,
          &:active {
            text-decoration-color: var(--lmig-IconButton-font-color);

            .lm-Icon {
              path {
                fill: var(--lmig-IconButton-icon-fill-color);
              }
            }
          }
        }
      }
    }
  }

  &--safeco {
    --lmig-FloatingHeader-brand-color: #{$lmig-color-libertyBlue};
  }

  // END Modifiers

  @include breakpoint-sm {
    --lmig-FloatingHeader-height: 80px;
    --lmig-FloatingHeader-margin-top: #{($lmig-spacing-default * 2)};

    &--isSticky {
      position: sticky;
    }

    &-inner {
      --lmig-FloatingHeader-height: 80px;
    }
  }

  &-appName {
    // do we want this hidden on smaller screens?
    display: flex;
    align-items: center;
    margin-right: $lmig-spacing-default;
    font-size: $lmig-typography-size-rem-x1125;
    color: var(--lmig-FloatingHeader-font-color);

    &-pipe {
      background-color: currentColor;
      width: 1px;
      height: 48px;
    }

    &-content {
      margin-left: $lmig-spacing-x15;
    }
  }

  &-brand {
    order: 0;
    flex: 0 0 100px;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    background-color: var(--lmig-FloatingHeader-brand-color);

    &-logo {
      flex: 0 0 72px;
    }

    @include breakpoint-sm {
      flex: 0 0 221px;

      &-logo {
        flex: 0 0 157px;
      }
    }
  }

  &-nav {
    order: 1;
    flex: 1 auto;
    @extend %lmig-FloatingHeader--flexRow;
    @include lmig-FloatingHeader-navVertical;
    @include lmig-FloatingHeader-menu-style;

    &-menu {
      > .lmig-FloatingHeader-item {
        // this flexes the anchor to fill the entire space for larger click area.
        > a {
          display: flex;
          align-items: center;
        }
      }

      &--highlight {
        height: 6px;
        position: absolute;
        border-radius: 4px 4px 0 0;
        bottom: 0;
        background: var(--lmig-FloatingHeader-item--active-background-color);
      }
    }

    .lm-Masthead & {
      display: flex;
    }
  }

  &-actions {
    order: 2;
    flex: 1 1 auto;
    align-items: center;
    @extend %lmig-FloatingHeader--flexRow;
    @include lmig-FloatingHeader-menu-style;

    &-menu {
      flex: 1 0 auto;
      gap: $lmig-spacing-x1;
      justify-content: flex-end;

      .lm-IconButton {
        --lmig-IconButton-font-color: #{$lmig-color-libertyBlue};

        flex-direction: row-reverse;
        gap: $lmig-spacing-x025;

        &:hover,
        &:focus,
        &:active {
          text-decoration-color: var(--lmig-IconButton-font-color);
        }

        &::before {
          left: 18px;
          top: 16px;
          z-index: 1;
        }

        &::after {
          left: 2px;
          margin: 0;
          position: relative;
        }

        .lm-IconWrapper {
          justify-content: normal;
          width: auto;

          .lm-Icon {
            // Due to using `flex-direction: row-reverse` this z-index is necessary in raising the icon above `:before` element
            z-index: 1;
            left: 10px;
            top: 8px;
          }

          .lm-Icon-label {
            font-size: $lmig-typography-size-rem-x0875;
            font-weight: $lmig-typography-weight-bold;
            color: var(--lmig-IconButton-font-color);
            text-align: right;
            z-index: 10;
          }
        }

        @include breakpoint-sm {
          gap: $lmig-spacing-x05;
        }
      }

      @include breakpoint-sm {
        gap: $lmig-spacing-x2;
      }
    }

    @include breakpoint-sm {
      gap: $lmig-spacing-x2;
    }
  }

  &-inner {
    @extend %lmig-FloatingHeader--flexRow;

    background-color: var(--lmig-FloatingHeader-background-color);
    backdrop-filter: blur(#{($lmig-spacing-default * 0.5)});
    height: var(--lmig-FloatingHeader-height);
    padding-right: $lmig-spacing-x1;
    border-radius: #{($lmig-spacing-default * 1.5)};
    box-shadow: var(--lmig-FloatingHeader-box-shadow);
    position: relative;

    &--cobrand,
    &--liberty,
    &--safeco {
      .lmig-FloatingHeader-nav-menu {
        .lmig-FloatingHeader-item {
          > .lm-Link {
            --lmig-Link-font-color: #{$lmig-color-libertyBlue};
          }
        }
      }
    }
    @include lmig-FloatingHeader-inner-vertical-open;
  }

  &-item {
    @include lmig-FloatingHeader-item;
  }

  &-menu-button {
    display: flex;
    order: 4;
    align-items: center;
    margin-left: auto;

    &-hidden {
      display: none;
    }
  }

  &-dropdown {
    &-button {
      background: none;
      border: none;
      font-size: inherit;
      font-weight: inherit;
      color: var(--lmig-Link-font-color);
      cursor: pointer;
      padding: 0;
      position: relative;

      &:hover {
        text-decoration: underline;
      }
    }

    &-menu {
      position: absolute;
      top: 100%;
      width: 100%;
      background: var(--lmig-FloatingHeader-vertical-menu-background-color);
      border-radius: 0 0 24px 24px;
      box-shadow: var(--lmig-FloatingHeader-box-shadow);
      overflow-x: hidden;
      border-top: 1px solid $lmig-color-palette-gray25;
    }
  }
}

.mega-menu-custom-link-pl {
  color: #fff !important;
  height: 100%;
}

.mega-menu-custom-link-bl {
  color: #1a1446 !important;
  height: 100%;
}

.mega-menu-custom-link-a-tag {
  text-decoration: none;
  font-size: inherit;
}

@media only screen and (min-width: 480px) and (max-width: 959px) {
  .nav-links-section {
    padding-left: 12px;
  }
}
