admintools {
  width: 100%;
}

.user-info {
  font-size: 14px;
  .simple-table {
    td {
      padding: 5px;
    }
  }
}
