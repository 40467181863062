@import '~@lmig/lmds-tokens/dist/scss/core/color';
@import '~@lmig/lmds-tokens/dist/scss/core/typography';
@import '~@lmig/lmds-tokens/dist/scss/core/border';

coveosearchpage {
  width: 100%;
}

.select-no-label {
  select {
    height: 40px !important;
    padding: 6px !important;
  }

  svg {
    top: 12px !important;
  }
}

.search-page {
  font-family: Roboto, sans-serif;
  font-size: $lmig-typography-size-rem-default;
  line-height: $lmig-typography-lineHeight-medium;

  .lm-FieldOption-text {
    height: auto !important;
  }

  .lm-h1 {
    font-weight: $lmig-typography-h1-default-weight-default;
    font-size: $lmig-typography-h1-default-fontsize-desktop;
    line-height: $lmig-typography-h1-default-lineheight;
  }

  .lm-h4 {
    font-weight: $lmig-typography-h4-weight-medium;
    font-size: $lmig-typography-h4-fontsize-desktop;
    line-height: $lmig-typography-h4-lineheight;
  }

  .caption {
    font-size: $lmig-typography-size-rem-x0875;
    line-height: $lmig-typography-lineHeight-medium;
  }

  .search-page-align-end {
    display: flex;
    justify-content: end;
    align-items: end;
    -webkit-align-items: end;
    -webkit-justify-content: end;
  }

  .search-page-hide-no-content {
    &:empty {
      display: none;
    }
  }

  .search-page-section-large-padding {
    padding: 15px 0px;
  }

  .search-page-section {
    padding: 10px 0px;
    width: 100%;

    &:empty {
      display: none;
    }
  }

  .search-page-divider {
    border-bottom: 1px solid $lmig-color-border-light;

    &:empty {
      display: none;
    }
  }

  #search-page-sort {
    .lm-FieldGroup-field .lm-FieldGroup-field--select {
      margin: 0px;
    }

    .lm-FieldGroup-field {
      margin: 0px;
    }
  }

  .search-facet {
    border: 1px solid $lmig-color-border-light;
    border-radius: 4px;
    padding: 20px;
    margin-right: 50px;
    margin-bottom: 10px;
    text-transform: capitalize;

    .search-facet-title {
      font-weight: $lmig-typography-h6-default-weight-medium;
      font-size: $lmig-typography-h6-default-fontsize-desktop;
      line-height: $lmig-typography-h6-default-lineheight;
      margin-bottom: 10px;
    }

    .search-facet-search {
      width: auto;
      max-width: 200px;
    }

    .search-facet-link {
      text-decoration: none;
      margin-top: 10px;
      margin-right: 20px;
    }

    .lm-FieldGroup-field--checkbox {
      margin: 0px;
    }
  }

  .search-page-facet-breadcrumb {
    font-weight: $lmig-typography-size-rem-default;
    text-transform: capitalize;
    background-color: $lmig-color-palette-gray04;
    border-radius: $lmig-border-form-input-radius;
    padding: 6px 10px 5px 10px;
    display: inline-block;

    div {
      display: inline-flex;
      color: $lmig-color-text-default;
      align-items: center;

      > div {
        padding-right: 10px;
        display: inline;

        > span {
          vertical-align: middle;
        }
      }
    }
  }

  .search-pager {
    display: flex;

    .clickable {
      cursor: pointer;

      &:hover {
        background-color: $lmig-color-palette-gray04;
        border-radius: 16px;
      }
    }

    .search-pager-element {
      width: 32px;
      height: 32px;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    .page-selected {
      border-bottom: 2px solid $lmig-color-palette-tealMedium;

      &:hover {
        border-radius: 0px !important;
      }
    }
  }

  .results-per-page {
    display: inline-flex;
    align-items: end;

    .search-per-page-selector {
      min-width: 150px;
      margin-left: 10px;
    }
  }

  .result-folding {
    ul {
      text-align: left;
    }

    .result-date {
      color: $lmig-color-palette-gray44;
      text-transform: capitalize;
    }

    .result-folding-result {
      padding: 20px 0 20px 0;
      border-bottom: 1px solid silver;

      &:last-child {
        border-bottom: 0px;
        padding: 20px 0 10px 0;
      }

      li {
        padding: 0;

        div {
          margin: 0;
        }
      }

      .result-folding-title {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;

        span.highlightedText {
          font-size: $lmig-typography-size-rem-x1125;
          line-height: $lmig-typography-lineHeight-medium;
        }
      }

      .result-folding-excerpts {
        margin-bottom: 15px;
      }
    }
  }
}

.standalone-search {
  display: flex;
  border-radius: $lmig-border-form-input-radius;
  border: 1px solid #919191;
  max-width: 639px;
  height: 52px;

  .lm-FieldGroup-field {
    margin: 0px;
  }

  .lm-FieldGroup-field--input .lm-Field-input,
  .lm-FieldGroup-field--input .lm-FieldGroup-input {
    border: 0px;
  }

  .standalone-search-select-wrapper {
    border-right: 1px solid silver;
    border-radius: 3px;
    display: inline-block;
    height: 100%;
    position: relative;
    min-width: 100px;
    max-width: 155px;
    width: 100%;

    .lm-FieldGroup-field--select .lm-Field-select,
    .lm-FieldGroup-field--select .lm-FieldGroup-select {
      border: 0px;
    }
  }

  .standalone-search-icon-wrapper {
    background-color: $lmig-color-palette-libertyYellow;
  }
}

.search-input-suggestion-wrapper {
  clear: both;
  position: relative;

  .search-input-wrapper {
    display: flex;

    .search-input {
      flex-grow: 1;
    }
  }

  .search-suggest {
    position: absolute;
    width: 100%;
    z-index: 999;
    top: 46px !important;

    .search-suggest-content {
      margin: 4px 0;
      overflow: hidden;
      font-family: Roboto, sans-serif;
      font-size: $lmig-typography-size-rem-default;
      line-height: $lmig-typography-lineHeight-medium;
      line-height: 1.5;
      letter-spacing: 0.00938em;
      box-shadow:
        rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
      border-radius: 4px;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: #fff;

      .search-suggest-list {
        margin: 0;
        padding: 8px 0;
        overflow: auto;
        list-style: none;
        max-height: 40vh;

        .search-suggest-list-item {
          cursor: pointer;
          display: flex;
          outline: 0;
          box-sizing: border-box;
          min-height: 48px;
          align-items: center;
          padding-top: 6px;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 6px;
          justify-content: flex-start;
          -webkit-tap-highlight-color: transparent;

          &:hover {
            background-color: #f2fcfc !important;
          }
        }
      }
    }
  }
}

.search-input-suggestion-wrapper input:hover {
  background-color: #f2fcfc !important;
}

.quick-view-link {
  margin-left: 10px;
  text-decoration: none;
  margin-top: 5px;
  margin-right: auto;
}

.quick-view-content {
  min-width: 400px;
  width: 100%;
}

.xlarge {
  section {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}

.nav-search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 1px; // Makes vertical align center look better due to drop shadow
}

coveostandalonesearchbox {
  flex-grow: 1;
}

.standalone-search-slideout-wrapper {
  display: flex;
  align-items: center;

  .lm-IconButton--hasOutline:after {
    border: 1px solid $lmig-color-palette-gray73;
  }

  .search-input-suggestion-wrapper {
    position: absolute;
    inset: 15px 508px 0 221px;

    .search-suggest {
      position: absolute;
      width: 100%;
      z-index: 999;
      top: 46px !important;

      .search-suggest-content {
        margin: 4px 0;
        overflow: hidden;
        font-family: Roboto, sans-serif;
        font-size: $lmig-typography-size-rem-default;
        line-height: $lmig-typography-lineHeight-medium;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        box-shadow:
          0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;

        .search-suggest-list {
          margin: 0;
          padding: 8px 0;
          overflow: auto;
          list-style: none;
          max-height: 40vh;

          .search-suggest-list-item {
            cursor: pointer;
            display: flex;
            outline: 0;
            box-sizing: border-box;
            min-height: 48px;
            align-items: center;
            padding-top: 6px;
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 6px;
            justify-content: flex-start;
            -webkit-tap-highlight-color: transparent;

            &:hover {
              background-color: #f2fcfc !important;
            }
          }
        }
      }
    }
  }
}

#search-icon {
  z-index: 801;
}

#search-input-parent-container {
  display: flex;
}

.search-input-suggestion-wrapper > .lm-FieldGroup-wrapper > .lm-FieldGroup-field > .lm-FieldGroup-inner > input {
  border-radius: 15px;
}

@media only screen and (max-width: 959px) {
  .search-input-suggestion-wrapper {
    inset: 15px 100px 0px 20px !important;
  }
}

@media only screen and (max-width: 479px) {
  .search-input-suggestion-wrapper {
    inset: 12px 85px 10px 12px !important;
  }
}
