@import 'Styles/GlobalVariables';

#commission-statement-access-form {
  .add-another-input-row {
    display: flex;
    & > div:not(.add-another-link) {
      width: 100%;
    }
    & > div:not(:last-child) {
      margin-right: 10px;
    }
  }

  .add-another-link {
    margin: 1em 0;
    display: flex;
    align-items: center;
    color: $font-link-blue;
    svg {
      margin-right: 10px;
    }
    svg path {
      fill: $font-link-blue;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .mail-to-link {
    display: flex;
    margin-left: 50px;
    width: fit-content;
    height: fit-content;
    margin-top: auto;
  }

  .cs-form-box {
    border: 1px solid lightgray;
    border-radius: 2px;
    padding: 24px;
  }

  .cs-form-agency-row {
    display: flex;
    margin-top: 24px;
    .cs-form-label {
      font-size: 12px;
      margin-right: 2em;
    }
  }
  .cs-subtext {
    font-weight: 300;
  }
  .cs-form-caption {
    font-size: 18px;
    margin-top: 2em;
  }

  .cs-form-description {
    font-size: 24px;
    font-weight: 300;
    margin-top: 0;
  }

  #commission-access-fieldgroup {
    margin-top: 1em;
    width: 323px;
  }
  #additional-users-fieldgroup {
    margin-top: 0px;
    max-width: 100%;
    & > div:nth-child(2) {
      width: calc(100% - 93px);
    }
  }

  .cs-bottom-row {
    display: flex;
  }
}
