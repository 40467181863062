@import 'Styles/GlobalVariables';

watermitigation {
  max-width: 1280px;
  display: block;
  margin: auto;
}

.wm-search-box {
  display: flex;
  border: 1px solid $border-light-gray;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  border-radius: 3px;
  .lm-FieldGroup-field--input {
    margin-bottom: 0;
    #alphaNumericInput1-input {
      min-width: 320px;
    }
  }
  button {
    max-height: 50px;
    align-self: end;
  }
}

.wm-subtext {
  padding-bottom: 20px;
}

.wm-spinner {
  margin: auto;
  margin-top: 50px;
}

.wm-vendor {
  display: flex;
  align-items: center;
  border: 1px solid $border-light-gray;
  border-radius: 3px;
  margin: 15px 0px;
  flex-wrap: wrap;
  position: relative;
  .lm-Heading {
    margin-right: auto;
    margin-left: 15px;
  }
  .wm-vendor-phone {
    margin-right: 15px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    svg {
      margin-right: 5px;
    }
  }
  .wm-vendor-recipient-id {
    white-space: nowrap;
    margin-right: 15px;
    min-width: 160px;
  }
}

.wm-no-results-message {
  margin: 1em auto;
  color: #0061f5;
  font-weight: 700;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
  svg path {
    fill: #0061f5;
  }
}

.wm-input-err {
  color: #d32f2f;
  font-weight: 700;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
    path {
      fill: #d32f2f;
    }
  }
}

/* Tablet styles -  On screens that are 992px or less */
@media screen and (max-width: 992px) {
  .wm-vendor .lm-Heading {
    width: 100%;
    min-width: 100%;
  }
  .wm-vendor-recipient-id,
  .wm-vendor-phone {
    margin-bottom: 1em;
  }
}

/* Mobile phone styles -  On screens that are 479px or less */
@media screen and (max-width: 579px) {
  .wm-search-button {
    width: 100%;
    min-width: 100%;
  }
  .wm-search-box > div {
    width: 100%;
    margin-bottom: 10px;
  }
  .wm-vendor-phone {
    width: 100%;
  }
  .wm-vendor-recipient-id {
    width: 100%;
    margin-left: 15px;
  }
}
