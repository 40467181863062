@import 'Styles/GlobalVariables';

personalizationrow {
  width: 100%;
  display: inline-block;
  .personalization-row {
    display: flex;
    margin-top: 15px;

    .welcomeMsgSection {
      background-color: white;
      display: flex;
      flex-wrap: wrap;
      padding: 4px 8px 4px 8px;
      margin-right: auto;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 58px;
      letter-spacing: -0.005em;
      color: #343741;
      align-items: baseline;
      display: flex;
      border: 1px solid #ffd000;
      border-radius: 4px;
      flex-grow: 1;
      margin-right: 10px;
    }

    #personalization-row-rich-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
    }

    #personalization-row-rich-text > a {
      color: $font-link-blue;
      font-size: 16px;
      font-weight: 400;
    }

    .welcomeMsgSection {
      .welcomeMsg {
        margin-right: 15px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 58px;
        letter-spacing: -0.005em;
        color: #343741;
      }
    }

    .buttonSection {
      margin-left: auto;
      color: #181345;
      padding-top: 14px;
      padding-left: 16px;
      margin-left: auto;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
      width: 250px;
      height: 58px;
      position: relative;
      background: #ffffff;
      border-radius: 4px;
      background-image: url(../../../Images/Rocket.png);
      background-repeat: no-repeat;
      background-position: 194px 6px;
    }

    .buttonSection:hover {
      background-repeat: no-repeat;
      background-position: bottom right;
      background-origin: content-box;
      background-image: url(../../../Images/RocketSmokeFlames.png);
    }

    .buttonSectionWrapper:hover {
      text-decoration: underline #181345 !important;
    }

    .buttonSectionWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid #ffd000;
      border-radius: 4px;
      text-decoration: none !important;
      width: 253px;
      background-color: white;
    }

    .statusTxt {
      color: #181345;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      width: 39px;
      height: 18px;
      position: absolute;
      right: 24px;
      bottom: 4px;
    }
  }
}

@mixin wrap() {
  .personalization-row {
    flex-wrap: wrap;
  }
  .welcomeMsgSection {
    margin-right: 0 !important;
  }
  .buttonSectionWrapper {
    width: 100% !important;
    margin-top: 10px;
  }
  .buttonSection {
    width: 100% !important;
    background-position: calc(100% - 32px) 5px !important;
    &:hover {
      background-position: 100% 100% !important;
    }
  }
}

/* This breakpoint is somewhat arbitrary and was selected bc its around when the the sample text 'Your Gold Value Report is now available!' wraps*/
@media screen and (max-width: 660px) {
  @include wrap;
}

/*also wrap the personalization row when the slider is open*/
@media screen and (max-width: 1150px) {
  .slider.open + .sp-page-content {
    @include wrap;
  }
}
