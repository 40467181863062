@import '~@lmig/lmds-tokens/dist/scss/core/color';

$mobile: 375px;
$tablet: 768px;

.lm-Footer {
  padding-top: 0 !important;
  width: 100%;

  .portal-footer-top-section {
    background-color: $lmig-color-palette-libertyBlue;
    padding: 2rem;

    .portal-footer-flexbox.portal-footer-flexbox-link-rows {
      display: block;
    }

    .portal-footer-flexbox.portal-footer {
      align-items: start;
      width: 100% !important;

      .portal-content-links {
        font-size: 1rem;
        line-height: 1.6em;

        .footer-publisher-section {
          a {
            padding: 0px;
          }
        }
      }

      .portal-footer-social-icon-row {
        margin-bottom: 2rem;

        a {
          margin-left: 8px;
        }

        a:last-child {
          margin-left: 8px;
        }
      }

      .pl-footer-content {
        max-width: 100%;
      }

      .portal-content-links.lm-blue-footer {
        flex-wrap: wrap;
        text-wrap: wrap;

        .footer-publisher-section {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 1rem;

          .footer-publisher-column {
            margin-right: 4rem;
            margin-bottom: 1rem;

            .footer-publisher-column-header {
              font-weight: bold;
            }

            .footer-publisher-column-content {
              flex-direction: column;
              display: flex;
              align-items: start;
            }
          }
        }
      }
    }

    @media only screen and (max-width: $tablet) {
      .portal-footer-flexbox.portal-footer {
        flex-wrap: wrap;

        .pl-footer-content {
          order: 3;
        }

        .portal-footer-social-icon-row {
          order: 2;
        }
      }
    }

    @media only screen and (max-width: $mobile) {
      .portal-footer-flexbox.portal-footer {
        .pl-footer-content {
          order: unset;
        }

        .portal-footer-social-icon-row {
          order: unset;
          margin-left: 0;

          a:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .portal-footer-flexbox {
      display: flex;
      align-items: center;
      .ai-disclaimer {
        padding: 15px 0px;
        display: flex;
        color: white;
        font-size: 14px;
      }
      a {
        text-decoration: underline !important;

        &:hover {
          text-decoration: none !important;
        }
      }

      .portal-footer-icon {
        padding-right: 40px;
        height: 75px;
        margin-bottom: 32px;
      }

      .portal-footer-icon-width {
        width: 110px !important;
      }

      .portal-content-links {
        height: auto;
        white-space: nowrap;
        width: auto;
        flex: 1;
        color: #fff;

        a {
          font-size: 16px;
          padding: 0px 3px;
        }

        svg path {
          fill: #fff;
        }

        .icon-link {
          display: flex;
          padding-top: 5px;
          flex-wrap: wrap;

          svg {
            margin-right: 5px;
          }
        }
      }

      .lm-blue-footer {
        display: flex;
        align-items: center;
        background-color: $lmig-color-palette-libertyBlue;
        flex-wrap: wrap;

        .lm-footer-image {
          padding: 0 60px 0 60px;
        }

        h1,
        h2,
        h3,
        h4 {
          color: #ffdf57;
        }

        a {
          color: white !important;
          text-decoration: underline !important;
          font-weight: normal !important;

          &:hover {
            text-decoration: underline !important;
          }
        }

        .footer-publisher-section {
          display: flex;
        }
      }

      .lm-LogoLibertyMutual--medallion,
      .lm-LogoSafeco--vert {
        width: 60px;
      }

      .portal-footer-social-icon-row {
        display: flex;
        justify-self: flex-end;
        margin-left: auto;
      }

      .portal-footer-social-icon-row a:last-child {
        margin-left: 16px;
      }
    }
  }

  .portal-footer-bottom-section {
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-size: 12px;
    margin: 0px !important;

    .lm-Disclaimer {
      p {
        font-size: 12px;
        font-family: Roboto, sans-serif;
        padding: initial;
        margin: initial;
      }
    }
  }
}
