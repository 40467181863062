@import 'Styles/GlobalVariables.scss';

#pl-quote-modal {
  .lmig-Modal-container {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    max-width: 840px;
  }

  #qm-select-a-state {
    background-color: #f2fcfc;
    border-top: 8px solid #2a3a56;
    padding: 0 2rem 1rem 2rem;

    .lm-FieldGroup-wrapper {
      max-width: 250px;
    }

    #qm-state-select {
      margin-top: 2rem;
    }
  }
  #qm-no-products-message {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  #qm-lower-section {
    padding: 0 2rem;
    .qm-product-sections {
      display: flex;
      padding-bottom: 20px;
      flex-wrap: wrap;
      .qm-product-section {
        padding-right: 2em;
        .qm-section-title {
          display: flex;
          align-items: center;
        }
        .qm-section-icon {
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: #ffd000;
          margin-right: 15px;
        }
        .qm-product-list {
          padding-left: 55px;
          a {
            display: block;
            padding-bottom: 7px;
            line-height: normal;
            &:hover {
              color: rgb(6, 116, 140);
            }
          }
        }
      }
    }
  }

  .lmig-Modal-header {
    padding-bottom: 24px;
    padding-left: 32px;
    padding-top: 8px;
  }

  .lmig-Modal-close {
    margin-right: 1rem;
  }

  .lmig-Modal-footer {
    border-top: 1px solid #e5e5e5;
    padding-top: 24px;
    padding-left: 2rem;

    p {
      margin-bottom: 0;
    }
  }
}

/*add extra padding in tile*/
.section-links-placeholder {
  quotemodal #pl-quote-button {
    margin-top: 1.5rem;
    margin-left: 2rem;
  }
}
// changing Get a quote button color for hover / focus actions
#bl-quote-modal:focus {
  color: #1a1446;
}

#bl-quote-modal:hover {
  color: white;
}

#bl-quote-modal {
  text-wrap: nowrap;
}

#pl-quote-button {
  text-wrap: nowrap;
}

#bl-quote-modal-tabMobile {
  text-wrap: nowrap;
  justify-content: center;
}

#pl-quote-button-tabMobile {
  text-wrap: nowrap;
  justify-content: center;
}
