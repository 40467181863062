@import 'Styles/GlobalVariables';

//moratoriums
.now-alert-wrapper {
  flex-grow: 2;

  .sco-notification {
    &.sco--caution {
      border-color: #b85d00;

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg fill='%23ffffff' xmlns='http://www.w3.org/2000/svg' focusable='false' role='img' viewBox='0 0 16 16'%3E%3Cpath d='M7.9939,0.5196 C8.9899,0.5196 9.8789,1.0386 10.3699,1.9086 L14.4479,8.9686 L13.5819,9.4686 L9.5019,2.4046 C9.1879,1.8496 8.6249,1.5196 7.9939,1.5196 C7.3629,1.5196 6.7999,1.8496 6.4879,2.4006 L1.2209,11.5186 C0.9139,12.0666 0.9209,12.7256 1.2439,13.2766 C1.5609,13.8176 2.1219,14.1416 2.7459,14.1416 L13.2419,14.1416 C13.8719,14.1416 14.4379,13.8116 14.7569,13.2596 C15.0749,12.7086 15.0779,12.0586 14.7659,11.5186 L14.3309,10.7596 L15.1979,10.2616 L15.6329,11.0196 C16.1249,11.8706 16.1209,12.8956 15.6229,13.7586 C15.1229,14.6246 14.2329,15.1416 13.2419,15.1416 L2.7459,15.1416 C1.7629,15.1416 0.8789,14.6336 0.3809,13.7826 C-0.1231,12.9216 -0.1341,11.8916 0.3519,11.0236 L5.6189,1.9046 C6.1089,1.0386 6.9969,0.5196 7.9939,0.5196 Z M8.861,10.922 L8.861,12.587 L7.265,12.587 L7.265,10.922 L8.861,10.922 Z M8.71,5.042 L8.56,9.54 L7.56,9.54 L7.397,5.042 L8.71,5.042 Z'/%3E%3C/svg%3E");
      }
    }
  }

  .now-alert {
    background-color: $bg-warning-text;
    display: table-row;

    .icon {
      background-color: $bg-warning;
      color: #fff;
      padding: 10px 20px;
      display: table-cell;
      font-size: 20px;
      vertical-align: middle;
    }

    .message {
      border: 2px solid $bg-warning;
      padding: 12px 20px 10px 20px;
      display: table-cell;
      font-weight: 400;
      font-size: 14px;
      color: #000;
      width: 100%;

      a {
        color: #0e66a8;
        font-size: 14px;
      }
    }
  }

  &#moratorium-ecat-alert {
    .embolden {
      font-weight: 700;
    }

    a {
      text-decoration: underline;
    }

    a:hover {
      text-decoration: none;
    }

    #moratorium-ecat-alert-modal {
      z-index: 1101;
    }

    .modal-header {
      color: $font-pure-white;
      background: $bg-dark-gray;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;

      .close {
        color: #fff;
        text-shadow: unset;
        opacity: 1;

        &:hover {
          color: #fff;
        }
      }
    }

    .modal-body {
      color: #000;
      overflow-y: auto;
    }

    .modal-dialog {
      .modal-content {
        border: unset;
      }
    }
  }

  #moratorium-ecat-alert-list {
    div {
      font-weight: 700;
      font-size: 24px;
      padding-bottom: 15px;
    }

    li {
      color: $font-dark-gray;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .countyItem {
    display: flex;
    flex-direction: column;
    width: 28%;
    margin-left: 5%;
    word-wrap: break-word;
  }

  .countyParent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

#moratorium-ecat-alert-modal {
  .lmig-Modal-container {
    max-width: 70vw;
    max-height: 70vh;
    margin: 15vh 15vw;
    padding: 0.75rem 0.75rem 2rem 0.75rem;
  }
}

.alertBannerContainer {
  background-color: white;
  display: flex;
  border-color: $border-light-gray $border-light-gray $border-light-gray #df0c0c;
  border-style: solid;
  border-width: 1px 1px 1px 10px;
  padding: 25px;
  flex-flow: row nowrap;
  border-radius: 10px;
  width: 100%;
  max-width: 1280px;
  margin: 15px auto 0 auto;
  height: fit-content;
}
.alertBannerIconContainer {
  padding-right: 10px;
}
.alertBannerIconClose {
  margin-left: auto;
  padding-left: 10px;
}
.alertBannerIconClose:hover {
  cursor: pointer;
}
.alertBannerHeader {
  font-weight: bold;
  color: #df0c0c;
}
.alertBannerContent {
  font-family: Roboto, sans-serif;

  a {
    font-size: 1em;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
}
alertbanner {
  width: 100%;
}

//generic alert

.alert-card {
  border: 1px solid #c0bec0;
  border-radius: 3px;
  background-color: white;
  min-width: 256px;
  .alert-body {
    padding: 1.5rem 1rem;
  }

  .alert-footer {
    border-top: 1px solid #c0bec0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1rem 0rem 2.5rem;
  }
  margin-bottom: 20px;

  .alert-description {
    /*custom styles and overides for rich text field in alert*/
    display: flex !important;
    padding: 1rem 1rem 0 1.5rem;
    a {
      font-size: 16px;
      text-decoration: underline;
    }

    a:hover {
      text-decoration: none;
    }
  }
}

.alert-confirmation-modal {
  .lmig-Modal-container {
    width: 350px;
  }
  .lm-ButtonClose {
    margin-right: 0px !important;
  }
}
