@import 'Styles/GlobalVariables';

.portal-tile-v2-wrapper {
  height: 100%;
}
.portal-tile-v2 {
  border: solid 1px #f5f5f5;
  border-radius: 8px;
  height: 436px;
  position: relative;
  overflow-y: hidden;
  strong {
    font-weight: bold !important;
  }
  .tile-agency-code {
    font-family: Roboto, sans-serif;
    font-weight: 300;
  }
  .portal-tile-v2-header-title-container {
    text-align: left !important;
    display: flex;
    flex-wrap: wrap;
    font-size: 24px;
    align-items: baseline;
    text-align: left !important;
    padding-left: 0px;
  }
  .portal-tile-v2-header {
    display: flex;
    width: 100%;
    padding-bottom: 0px;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 24px;
    margin: 0px;
  }
  .portal-tile-v2-header-link {
    font-size: 16px;
  }
  .portal-tile-v2-header-title-container > div:nth-child(2) {
    font-size: 15px;
    text-align: left !important;
  }
  @media screen and (max-width: 768px) {
    .portal-tile-v2-header-link {
      top: 12px;
    }
    .portal-tile-v2-header-title-container {
      padding-top: 24px;
      display: flex;
      flex-direction: column;
    }
  }

  .sub-header {
    font-weight: lighter;
  }

  .portal-tile-v2-body {
    padding: 24px;
    font-size: 18px !important;
    display: flex;
    font-size: 18px !important;
    justify-content: center;
    padding: 24px;
    .lmig-Tabs {
      position: static;
      padding-top: 16px;
    }
    a {
      color: $font-link-blue !important;
      font-size: 16px !important;
      text-decoration: none !important;
      &:hover {
        color: $font-link-blue !important;
        text-decoration: underline !important;
      }
    }

    li {
      list-style: inside;
    }
  }

  .portal-tile-v2-header-title-container {
    display: flex;
    padding-left: 0px;
    text-align: left !important;

    .custom-tile-heading-margin {
      margin: 0;
      margin-right: 5px;
    }
  }
  .portal-tile-v2-header-title-container > div:nth-child(2) {
    text-align: left !important;
  }

  .portal-tile-v2-header {
    display: flex;
    padding: 16px 16px 0;
    justify-content: space-between;
  }

  strong {
    font-weight: bold !important;
  }

  @media screen and (max-width: 575px) {
    .portal-tile-v2-body {
      padding: 13px;
    }
    .portal-tile-v2-header-link {
      top: 12px;
    }
    .portal-tile-v2-header-title-container-haslink {
      display: flex;
      flex-direction: column;
      padding-top: 24px;
    }
    .portal-tile-v2-header {
      padding: 10px;
      padding-bottom: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .portal-tile-v2 {
    height: 100%;
  }
}

.rich-text-tile-v2-full-width {
  width: 100%;
}

.tile-footer-date {
  position: absolute;
  font-size: 12px;
  bottom: 16px;
  left: 16px;
  line-height: normal;
}
