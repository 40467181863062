@import 'Styles/GlobalVariables';

.uscm-standard-carousel {
  margin-top: 15px;
}

.carousel-tablist-highlight {
  height: 0 !important;
}

.container {
  padding: 0 !important;
}

.row-splitter > .container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#content {
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 50px;
  justify-content: flex-end;

  .row {
    width: 100%;
    max-width: 1280px;

    &:first-child {
      height: auto !important;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.sp-middle-content {
  display: flex;
}

.sp-page-content {
  max-width: 1280px;
  margin: 0 auto;
  flex-grow: 1;
  padding: 0 15px;
  #content {
    display: flex !important;
  }
}
//when slider is open have table row content wrap
@media only screen and (max-width: 1280px) {
  .slider.open + .sp-page-content {
    .table-row .column-splitter {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 0px;
      .col-xs-12 {
        width: 100% !important;
        padding-left: 0px;
        padding-right: 0;
        padding-bottom: 16px;
      }
    }
  }
}

@media only screen and (max-width: 1081px) {
  .sp-page-content {
    width: 100%;
    //wrap tiles in table row at 1081px
    .table-row .column-splitter {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 0px;
      .col-xs-12 {
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 16px;
      }
    }
  }
}

.slider.open + .sp-page-content {
  width: calc(100% - 405px);
}

.hidden {
  display: none;
}

.section-links .tools-links > ul > a > li {
  color: $font-link-blue !important;
  text-decoration: none !important;

  &:hover {
    color: $font-link-blue !important;
    text-decoration: underline !important;
  }
}

.now-component-richtexttile-wrapper .now-component-richtexttile-richtext-wrapper a {
  color: $font-link-blue !important;
  text-decoration: none !important;

  &:hover {
    color: $font-link-blue !important;
    text-decoration: underline !important;
  }
}

body {
  min-width: auto !important;
}

@media only screen and (max-width: 1081px) {
  .QSIFeedBackLink {
    top: unset !important;
    bottom: 275px !important;
    z-index: 100 !important;
  }

  main {
    position: relative !important;
  }

  #content {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

div[aria-label='Chat'] {
  height: 48px;
  width: 48px;
  position: fixed;
  bottom: 225px;
  right: 20px;
}
