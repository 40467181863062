.lmbl {
  .carousel {
    position: relative;
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .carousel-inner > .item {
    position: relative;
    display: none;
    -webkit-transition: 0.6s ease-in-out left;
    -o-transition: 0.6s ease-in-out left;
    transition: 0.6s ease-in-out left;
  }

  .carousel-inner > .item > img,
  .carousel-inner > .item > a > img {
    line-height: 1;
  }

  @media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-inner > .item {
      -webkit-transition: -webkit-transform 0.6s ease-in-out;
      -o-transition: -o-transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-perspective: 1000px;
      perspective: 1000px;
    }

    .carousel-inner > .item.carousel-item-next,
    .carousel-inner > .item.active.right {
      left: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    .carousel-inner > .item.prev,
    .carousel-inner > .item.active.carousel-item-left {
      left: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }

    .carousel-inner > .item.carousel-item-next.carousel-item-left,
    .carousel-inner > .item.prev.right,
    .carousel-inner > .item.active {
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .carousel-inner > .active,
  .carousel-inner > .carousel-item-next,
  .carousel-inner > .prev {
    display: block;
  }

  .carousel-inner > .active {
    left: 0;
  }

  .carousel-inner > .carousel-item-next,
  .carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .carousel-inner > .carousel-item-next {
    left: 100%;
  }

  .carousel-inner > .prev {
    left: -100%;
  }

  .carousel-inner > .carousel-item-next.carousel-item-left,
  .carousel-inner > .prev.right {
    left: 0;
  }

  .carousel-inner > .active.carousel-item-left {
    left: -100%;
  }

  .carousel-inner > .active.right {
    left: 100%;
  }

  .carousel-control {
    top: 0;
    bottom: 0;
    font-size: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0);
    filter: alpha(opacity=50);
  }

  .carousel-control.carousel-item-left {
    background-repeat: repeat-x;
  }

  .carousel-control.right {
    left: auto;
    background-repeat: repeat-x;
  }

  .carousel-control:hover,
  .carousel-control:focus {
    color: #fff;
    text-decoration: none;
    filter: alpha(opacity=90);
    outline: 0;
    opacity: 0.9;
  }

  .carousel-control .icon-prev,
  .carousel-control .icon-next,
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
  }

  .carousel-control .icon-prev,
  .carousel-control .glyphicon-chevron-left {
    left: 50%;
    margin-left: -10px;
  }

  .carousel-control .icon-next,
  .carousel-control .glyphicon-chevron-right {
    right: 50%;
    margin-right: -10px;
  }

  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 20px;
    height: 20px;
    font-family: serif;
    line-height: 1;
  }

  .carousel-control .icon-prev:before {
    content: '‹';
  }

  .carousel-control .icon-next:before {
    content: '›';
  }

  .carousel-indicators {
    z-index: 15;
    text-align: center;
    list-style: none;
  }

  .carousel-indicators li {
    display: inline-block;
    text-indent: -999px;
    cursor: pointer;
    background-color: #000 \9;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #fff;
    border-radius: 10px;
  }

  .carousel-caption {
    position: absolute;
    bottom: 20px;
    z-index: 10;
    padding-top: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  }

  .carousel-caption .btn {
    text-shadow: none;
  }

  @media screen and (min-width: 768px) {
    .carousel-control .glyphicon-chevron-left,
    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-prev,
    .carousel-control .icon-next {
      width: 30px;
      height: 30px;
      margin-top: -10px;
      font-size: 30px;
    }

    .carousel-control .glyphicon-chevron-left,
    .carousel-control .icon-prev {
      margin-left: -10px;
    }

    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-next {
      margin-right: -10px;
    }

    .carousel-caption {
      right: 20%;
      left: 20%;
      padding-bottom: 30px;
    }

    .carousel-indicators {
      bottom: 20px;
    }
  }

  .carousel-inner > .item > a > img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}
