@import '../RecentActivitiesTable.scss';
.flexible-content-tile-container {
  .lmig-Table-header {
    border-top: none !important;
  }

  #policies-table-header {
    border-top: none;
    font-weight: 700;

    .small-column-header {
      width: 100px;
    }
  }

  #policies-table-header {
    .policies-table-effective-date {
      text-align: left;
    }
  }
  .policies-table-effective-date > div {
    padding-left: 8px !important;
    text-align: left;
  }
  .policies-table-transaction > div {
    text-align: right;
    padding-right: 8px !important;
  }
  .policies-customer-name-container {
    display: flex;
    align-items: center;
    .policy-icon {
      margin: 0 8px;
    }
  }
}

.MuiPaper-root.MuiCard-root.filter-tile.MuiPaper-elevation1.MuiPaper-rounded {
  box-shadow: none;
}

.filter-menu {
  margin-left: 15px;
}

#demo-table {
  text-wrap: nowrap;
}

.no-data-found {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lm-FieldGroup-field--checkbox * {
  box-sizing: inherit;
  display: grid;
  grid-template-columns: repeat(auto-fit, 170px);
}

.filter-tile {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.filter-tile.expanded {
  max-height: 300px;
}

.policy-transactions-graphic > div {
  margin-left: auto;
  margin-top: 4px;
  flex-grow: 1;
  display: flex;
  border-radius: 4px;
  font-size: 14px;
}

.policy-transactions-graphic > div > div {
  flex-grow: 1;
}

.filter-title {
  display: flex;
  margin-top: 15px;
  margin-left: 20px;
  width: 195px;
  font-weight: bold;
  white-space: nowrap;
}

.policy-transactions-graphic > div {
  padding-top: 10px;
  display: flex;
}
.policy-transactions-graphic > div > div:nth-child(even) {
  margin-left: auto;
}
.policy-transactions-graphic > div > div:nth-child(odd) {
  margin-right: 20px;
  width: 200px;
  display: flex;
  flex-direction: column;
  border: 1px solid #c0bfc0;
}

recentactivitiestile {
  .portal-tile-v2-body {
    padding: 0 16px !important;
  }
}

.lm-Fieldoption-text {
  margin-right: 3px;
}

.tile-child {
  flex: 1;
}

.lm-FieldGroup-field--checkbox .lm-FieldOption {
  cursor: pointer;
  display: block;
  position: relative;
  margin-right: 20px;
  margin-bottom: 0px;
}

.flexible-content-tile-container .tile-child:last-child {
  flex-grow: 3;
}
