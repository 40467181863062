.guides {
  margin-bottom: 2em;
}
.guides-list {
  border-top: 6px solid #ffd000;
  max-width: 430px;
  list-style: none;
  padding-left: 0;
}

.guides-list-content {
  background-color: #f5f5f5;
}

.guides-list li a {
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #fff;
  margin-left: 20px;
  width: 312px;
  font-size: 14px;
  text-decoration: none !important;
}

.guides .lm-Heading svg {
  margin: 0 10px;
}

.guides .loading-spinner {
  padding-top: 30px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.no-guides-message {
  padding: 1em;
  text-align: center;
  font-size: 12px;
}
