@import '~@lmig/lmds-tokens/dist/scss/core/color';

newsarticlemetadata {
  width: 100%;

  .news-metadata-container {
    margin-bottom: 24px;
  }

  .headline-container {
    margin-bottom: 1rem;

    .headline {
      margin-bottom: 0px;
      margin-top: 16px;
    }
  }

  .news-article-metadata {
    padding: 20px;
    background-color: $lmig-color-atmospheric;
    font-weight: 700;
  }
}
