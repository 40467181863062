@import '../../styles/foundations';
@import 'Styles/GlobalVariables';
@import './themes/dark';

$lmig-accordion-backgroundColor: $lmig-color-white;
$lmig-accordion-header-fontSize: lmig-convert-to-rem(18px);
$lmig-accordion-header-fontColor: $lmig-color-grayDark;
$lmig-accordion-header-fontWeight: $lmig-typography-weight-medium;
$lmig-accordion-header-lineHeight: 1.4;
$lmig-accordion-header-padding: 19px 24px 18px; // needs to add up to 64px when there is no line break (to match sketch symbols)
$lmig-accordion-header-hover-cirle-backgroundColor: $lmig-color-atmospheric;
$lmig-accordion-header-hover-cirle-size: 32px;
$lmig-accordion-listItem-borderTop: 1px solid var(--lmig-Accordion-border-color);
$lmig-accordion-listItem-borderBottom: $lmig-accordion-listItem-borderTop;
$lmig-accordion-content-fontSize: lmig-convert-to-rem(16px);
$lmig-accordion-content-fontColor: $lmig-color-grayDark;
$lmig-accordion-content-fontWeight: $lmig-typography-weight-regular;
$lmig-accordion-content-lineHeight: 1.6;
$lmig-accordion-content-padding: 8px 64px 24px 0px;

// mobile
$lmig-accordion-mobile-header-fontSize: lmig-convert-to-rem(16px);
$lmig-accordion-mobile-header-padding: 16px 8px; // needs to add up to 56px when there is no line break (to match sketch symbols)
$lmig-accordion-mobile-content-padding: 8px 48px 24px 0px;

// hasIcon
$lmig-accordion-header-icon-width: 32px;
$lmig-accordion-header-icon-marginRight: 16px;

// hasIcon x mobile
$lmig-accordion-mobile-header-icon-width: 24px;
$lmig-accordion-mobile-header-icon-marginRight: 12px;
$lmig-accordion-hasIcon-header-paddingTop: 16px;
$lmig-accordion-hasIcon-header-paddingBottom: 16px;

// hasFullWidthContent
$lmig-accordion-hasFullWidthContent-content-padding: 8px 24px 24px 24px;

// hasFullWidthContent x mobile
$lmig-accordion-hasFullWidthContent-mobile-content-padding: 8px 8px 24px 8px;

// compact
$lmig-accordion-compact-header-fontSize: lmig-convert-to-rem(16px);
$lmig-accordion-compact-header-paddingTop: 20px; // needs to add up to 64px when there is no line break (to match sketch symbols)
$lmig-accordion-compact-header-paddingBottom: 20px; // needs to add up to 64px when there is no line break (to match sketch symbols)
$lmig-accordion-compact-listItem-borderTop: 1px solid var(--lmig-Accordion-border-color);
$lmig-accordion-compact-listItem-borderBottom: $lmig-accordion-compact-listItem-borderTop;

// compact x mobile
$lmig-accordion-compact-mobile-header-paddingTop: 16px; // needs to add up to 56px when there is no line break (to match sketch symbols)
$lmig-accordion-compact-mobile-header-paddingBottom: 16px; // needs to add up to 56px when there is no line break (to match sketch symbols)
$lmig-accordion-compact-mobile-icon-width: 16px;

// compact x hasIcon
$lmig-accordion-compact-icon-width: 24px;

// list variant
$lmig-accordion-list-header-fontSize: lmig-convert-to-rem(16px);
$lmig-accordion-list-header-lineHeight: 1.4;
$lmig-accordion-list-header-padding: 10px 24px 10px 4px; // needs to add up to 44px when there is no line break (to match sketch symbols)
$lmig-accordion-list-content-padding: 8px 8px 24px 36px;

// list variant x mobile
$lmig-accordion-list-mobile-header-padding: 10px 8px 10px 4px; // needs to add up to 44px when there is no line break (to match sketch symbols)

.bl .accordion-placeholder {
  width: 100% !important;
}

.lmig-Accordion {
  --lmig-Accordion-background-color: #{$lmig-accordion-backgroundColor};
  --lmig-Accordion-font-color: #{$lmig-color-grayDark};
  --lmig-Accordion-border-color: #{$lmig-color-gray29};
  --lmig-Accordion-caret-background-color: #{$lmig-color-atmospheric};

  @include lmig-base-font;
  @include lmig-box-sizing;

  background-color: var(--lmig-Accordion-background-color);
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.lmig-Accordion-list {
  padding: 0;
  margin: 0;
}

.lmig-Accordion-list-listItem {
  font-size: $lmig-accordion-mobile-header-fontSize;
  color: var(--lmig-Accordion-font-color);
  font-weight: $lmig-accordion-header-fontWeight;
  border-top: $lmig-accordion-listItem-borderTop;
  display: block;
  margin: 0;
  padding: 0;
  line-height: $lmig-accordion-header-lineHeight;

  @media screen and (min-width: $lmig-breakpoint-sm-min) {
    font-size: $lmig-accordion-header-fontSize;
  }

  &:last-child {
    border-bottom: $lmig-accordion-listItem-borderBottom;
  }
}

.lmig-Accordion-list-listItem-header {
  @include lmig-inherit-font-styles;

  margin: 0;
  padding: 0;
}

.lmig-Accordion-list-listItem-header-button {
  @include lmig-inherit-font-styles;

  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  font: inherit;
  margin: 0;
  overflow: visible;
  padding: $lmig-accordion-mobile-header-padding;
  width: 100%;
  position: relative;

  @media screen and (min-width: $lmig-breakpoint-sm-min) {
    padding: $lmig-accordion-header-padding;
  }

  &:hover,
  &:focus {
    .lmig-Accordion-list-listItem-header-caret {
      &::after {
        top: -4px;
        left: -4px;
        width: $lmig-accordion-header-hover-cirle-size;
        height: $lmig-accordion-header-hover-cirle-size;
        transition: all lmig-interaction('enter');
      }
    }
  }
}

.lmig-Accordion--hasIcon {
  .lmig-Accordion-list-listItem-header-button {
    padding-top: $lmig-accordion-hasIcon-header-paddingTop;
    padding-bottom: $lmig-accordion-hasIcon-header-paddingBottom;
  }

  .lmig-Accordion-list-listItem-header-icon {
    display: block;
    margin-right: $lmig-accordion-mobile-header-icon-marginRight;
    overflow: hidden;
    width: $lmig-accordion-mobile-header-icon-width;
    min-width: $lmig-accordion-mobile-header-icon-width;

    @media screen and (min-width: $lmig-breakpoint-sm-min) {
      margin-right: $lmig-accordion-header-icon-marginRight;
      width: $lmig-accordion-header-icon-width;
      min-width: $lmig-accordion-header-icon-width;
    }
  }
}

.lmig-Accordion:not(.lmig-Accordion--compact) .lmig-Accordion--hasIcon {
  .lmig-Accordion-list-listItem-header-caret {
    top: 0;

    @media screen and (min-width: $lmig-breakpoint-sm-min) {
      top: 4px;
    }
  }
}

.lmig-Accordion-list-listItem-header-button-labelVisual {
  @include lmig-inherit-font-styles;
  flex-grow: unset !important;
  display: block;
  text-align: left;
  padding-right: 16px;
  margin-top: 2px;
  text-wrap: nowrap;
  text-decoration: none;
}

.lmig-Accordion-list-listItem-header-button-labelVisual:hover {
  color: $font-link-blue;
  text-decoration: underline;
}

.lmig-Accordion-list-listItem-header-caret {
  margin-left: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  width: 100% !important;
  height: 24px;
  position: relative;

  &::after {
    z-index: 0;
    background: none !important;
    content: '';
    position: absolute;
    top: 12px;
    left: 12px;
    width: 0;
    height: 0;
    border-radius: 100%;
    transition: all lmig-interaction('exit');
  }

  .lmig-Accordion-list-listItem-header-caret-svg {
    margin-left: auto;
    position: relative;
    z-index: 1;
    transition: transform lmig-interaction('exit');
  }
}

.lmig-Accordion-panel {
  &--entering,
  &--exiting {
    transition: height lmig-interaction('enter');
    overflow: hidden;
  }
}

.lmig-Accordion-content {
  font-size: $lmig-accordion-content-fontSize;
  line-height: $lmig-accordion-content-lineHeight;
  font-weight: $lmig-accordion-content-fontWeight;
  color: var(--lmig-Accordion-font-color);
  padding: $lmig-accordion-mobile-content-padding;

  @include breakpoint-sm {
    padding: $lmig-accordion-content-padding;
  }
}

// make sure nested Accordions do not animate the entire tree
.lmig-Accordion--isOpen {
  .lmig-Accordion-list-listItem-header-button {
    &:hover,
    &:focus {
      &::before {
        top: 16px;
      }
    }
  }

  > .lmig-Accordion-list-listItem-header {
    > .lmig-Accordion-list-listItem-header-button {
      .lmig-Accordion-list-listItem-header-caret-svg {
        transform: rotate(180deg);
        transition: transform lmig-interaction('enter');
      }
    }
  }
}

// no border top
.lmig-Accordion--noBorderTop {
  .lmig-Accordion-list-listItem:first-child {
    border-top: none;
  }
}

// list variant
.lmig-Accordion--list {
  .lmig-Accordion-list-listItem {
    font-size: $lmig-accordion-list-header-fontSize;
    border: none;

    .lmig-Accordion-list-listItem-header-button {
      padding: $lmig-accordion-list-mobile-header-padding;
      align-items: flex-start;

      @include breakpoint-sm {
        padding: $lmig-accordion-list-header-padding;
      }

      .lmig-Accordion-list-listItem-header-caret {
        &::after {
          top: 4px;
        }
      }

      &:hover,
      &:focus {
        .lmig-Accordion-list-listItem-header-caret {
          &::after {
            width: 24px;
            height: 24px;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  .lmig-Accordion-list-listItem-header-button {
    flex-direction: row-reverse;
  }

  .lmig-Accordion-list-listItem-header-caret {
    transform: rotate(-90deg);
  }

  .lmig-Accordion-list-listItem-header-button-labelVisual {
    margin-left: 8px;
    padding-right: 0;
    position: relative;
    z-index: 1;
    text-decoration: none;
  }

  .lmig-Accordion--isOpen {
    > .lmig-Accordion-list-listItem-header {
      > .lmig-Accordion-list-listItem-header-button .lmig-Accordion-list-listItem-header-caret-svg {
        transform: rotate(90deg);
        transition: transform lmig-interaction('enter');
      }
    }
  }

  .lmig-Accordion-content {
    padding: $lmig-accordion-list-content-padding;
  }
}

// compact
.lmig-Accordion--compact {
  --lmig-Accordion-border-color: #{$lmig-color-gray10};

  .lmig-Accordion-list-listItem {
    font-size: $lmig-accordion-compact-header-fontSize;
    border-top: $lmig-accordion-compact-listItem-borderTop;

    &:last-child {
      border-bottom: $lmig-accordion-compact-listItem-borderBottom;
    }
  }

  .lmig-Accordion-list-listItem-header-button {
    padding-top: $lmig-accordion-compact-mobile-header-paddingTop;
    padding-bottom: $lmig-accordion-compact-mobile-header-paddingBottom;

    @media screen and (min-width: $lmig-breakpoint-sm-min) {
      padding-top: $lmig-accordion-compact-header-paddingTop;
      padding-bottom: $lmig-accordion-compact-header-paddingBottom;
    }
  }

  .lmig-Accordion-list-listItem-header-icon {
    width: $lmig-accordion-compact-mobile-icon-width;
    min-width: $lmig-accordion-compact-mobile-icon-width; // for flexbox, sigh

    @media screen and (min-width: $lmig-breakpoint-sm-min) {
      width: $lmig-accordion-compact-icon-width;
      min-width: $lmig-accordion-compact-icon-width; // for flexbox, sigh
    }
  }
}

// full width content
.lmig-Accordion--hasFullWidthContent {
  .lmig-Accordion-content {
    padding: $lmig-accordion-hasFullWidthContent-mobile-content-padding;

    @include breakpoint-sm {
      padding: $lmig-accordion-hasFullWidthContent-content-padding;
    }
  }
}

@media screen and (min-width: 480px) {
  .lmig-Accordion-content {
    padding: 8px 24px 24px 0px !important;
  }
}

@media only screen and (max-width: 479px) {
  .lmig-Accordion-list-listItem-header-button-labelVisual {
    padding-left: 12px;
  }
}
