@import 'Styles/GlobalVariables.scss';

horizontalperk {
  width: 100%;
  text-align: center;

  .horizontal-perk-rich-text b,
  .horizontal-perk-rich-text strong {
    font-weight: bold;
  }

  .horizontal-perk-rich-text {
    line-height: normal;
  }

  .horizontal-perk-container {
    max-width: 824px;
    background-color: #f5f5f5;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 1rem;
    padding: 30px 0 15px 0;

    .horizontal-perk-content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around; // horizontal
      align-items: center; // vertical
      margin-left: 14%; // ~115px at 824px total width
      margin-right: 35px;

      a {
        color: $font-link-blue;
        font-size: 16px;
      }

      .horizontal-perk-rich-text {
        padding-bottom: 20px;
        margin-right: 35px;
        margin-left: 35px;
      }

      .horizontal-perk-button {
        padding-bottom: 20px;
        // margin-right: 35px;

        .lmig-Button {
          margin: 0;
        }
      }
    }
  }

  .horizontal-perk-container-one-third {
    height: 256px;
    width: 396px;
    background-color: #f5f5f5;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 1rem;
    padding: 25px 20px 25px 20px;

    h4 {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 29px;
    }

    .horizontal-perk-content {
      display: flex;
      flex-flow: row wrap;
      justify-content: center; // horizontal
      align-items: center; // vertical

      a {
        color: $font-link-blue;
        font-size: 16px;
      }

      .horizontal-perk-rich-text {
        margin-top: 85px;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
        font-family: Roboto, Arial, sans-serif;
        font-style: normal;
        line-height: 22px;
      }

      .horizontal-perk-button {
        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;

        .lmig-Button {
          margin: 0;
        }
      }
    }
  }
}
