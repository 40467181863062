.customer-search {
  .lm-Heading {
    margin-top: 10px;
  }

  p {
    padding-bottom: 15px;
  }

  .customer-search-button {
    margin: 0;
  }

  .lm-FieldGroup {
    margin: 0 !important;
  }
}
