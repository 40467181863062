.quote-card {
  .quote-card-header {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 18px;

    .lm-Heading {
      margin-bottom: 0;
    }
  }

  p {
    padding-bottom: 15px;
  }

  .lm-FieldGroup {
    margin: 0 !important;
  }

  .lm-field-select {
    padding: 0;
  }

  .quote-button {
    margin: 20px 0 0 0 !important;
  }

  .lm-Field-select {
    padding-top: 20px !important;
  }
}
