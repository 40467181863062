logout {
  display: block;
  width: 100%;
}
.logout-body {
  display: flex;
  align-items: center;
  margin: auto;
  max-width: 974px;
  flex-direction: column;
  padding-top: 2em;
  padding-bottom: 3em;
  height: 100%;
  .logout-top {
    padding: 2em;
    padding-bottom: 48px;
    svg {
      display: flex;
      margin: auto;
    }
  }
  .logout-actions {
    display: flex;
    border-top: 1px solid #d3d3d3;
    flex-wrap: wrap;
    margin: 1em;
    > div {
      padding-top: 15px;
    }
    div:first-child {
      padding-right: 2em;
    }
    ul {
      padding-left: 0;
      li {
        display: block;
      }
    }
  }
}

.logout-disclaimer {
  display: flex;
  margin: auto;
  justify-content: center;
}
