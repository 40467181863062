@import 'Styles/GlobalVariables';

$mobile: 768px;
$tablet-max: 1179px;
$laptop-min: 1180px;
$laptop-max: 1439px;
$desktop-min: 1440px;

.no-overflow-body {
  overflow-y: hidden;
  overflow-x: hidden;
}

.safeco.mega-menu-wrapper .header {
  background-color: $bg-liberty-blue;
}

.lm.mega-menu-wrapper .header {
  background-color: $bg-liberty-yellow;
}

.lm.mega-menu-wrapper .header .header-nav .menu-item {
  .menu-title {
    color: $default-black;
  }

  &.active,
  &:hover {
    border: 1px solid $default-black;
  }
}

.safeco.mega-menu-wrapper .header .header-nav .menu-item {
  .menu-title {
    color: $bg-white;
  }

  &.active,
  &:hover {
    border: 1px solid $bg-white;
  }
}

.mega-menu-wrapper {
  width: 100%;

  .lm-masthead {
    margin-left: 24px;
    margin-right: 18px;

    .lm-LogoLibertyMutual--horiz,
    .lm-LogoSafeco--horiz {
      width: 210px;
    }

    .lm-LogoLibertyMutual--vert,
    .lm-LogoSafeco--vert {
      width: 120px;
    }
  }

  .header {
    height: 104px;
    width: 100%;
    position: relative;
    background-color: $bg-liberty-yellow;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-view-port {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @media only screen and (min-width: $laptop-min) {
      .header-view-port {
        width: 100%;
      }
    }

    .hamburger {
      display: none !important;
    }

    .standalone-search-slideout-wrapper > div {
      width: 48px;
      height: 48px;
      margin-left: 24px;
      margin-bottom: 0;
    }

    .header-nav {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .menu-item {
        white-space: nowrap;
        margin: 10px 20px;
        margin: 0px 2px;
        display: flex;
        justify-content: center;

        .triangle {
          position: absolute;
          top: inherit;
          visibility: hidden;
          opacity: 0;
          margin-top: 42px;
          z-index: 9001;
        }

        .menu-title {
          display: block;
          font-size: 16px;
          font-weight: 500;
          padding: 8px 16px;
          border: 1px solid transparent;

          &:hover {
            text-decoration: none;
          }
        }

        &:hover {
          .megamenu-dropdown {
            opacity: 1;
            visibility: visible;
          }

          .triangle {
            transition: all 75ms ease-out 250ms;
            visibility: visible;
            opacity: 1;
          }
        }

        &:nth-child(-n + 2) .column-count-2 {
          left: -100px;
        }

        &:nth-last-child(-n + 2) .column-count-2 {
          right: -100px;
        }
      }
    }

    .tools-navigation {
      display: flex;
      align-items: center;
      white-space: nowrap;
      height: 100%;
      margin: 0px 24px;

      .lmig-Button {
        margin: 0px;
        border-top: 3px solid transparent;
      }
    }

    .mobile-navigation.sticky {
      position: sticky;
      right: 0;
      justify-content: flex-end;
    }

    .mobile-navigation {
      display: flex;
      align-items: center;
      white-space: nowrap;
      height: 100%;
      padding: 0 24px 0 8px;

      .lmig-Button {
        margin: 0px;
        border-top: 3px solid transparent;
      }
    }
  }

  .megamenu-dropdown {
    visibility: hidden;
    box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.18);
    background-color: white;
    transition: all 75ms ease-out 200ms;
    opacity: 0;
    position: absolute;
    overflow: hidden;
    margin-top: 60px;
    z-index: 9000;
    width: max-content;
    top: 0;

    &:hover,
    &:active {
      opacity: 1;
      visibility: visible;
    }

    &.column-count-1 {
      position: absolute;
    }

    &.column-count-2 {
      position: absolute;
    }

    &.column-count-3 {
      width: 900px;
      position: fixed;
      left: calc((100vw - 920px) / 2);
      top: 74px !important;

      .nav-column-rich-text {
        flex-grow: 2;
      }
    }

    &.column-count-4 {
      width: 1150px;
      position: fixed;
      left: calc((100vw - 1170px) / 2);
      top: 74px !important;

      .nav-column-rich-text {
        flex-grow: 2;
      }
    }

    .nav-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 40px 0px;
    }

    .nav-column-container {
      width: 100%;
      display: flex;
    }

    .navigation-section:first-child .lm-Heading {
      margin-top: 0px;
    }

    .navigation-section {
      margin: 0 25px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      .lm-Heading a {
        text-decoration: underline;
        font-size: unset;
      }

      .menu-section-links {
        display: flex;
        flex-direction: column;

        &:hover,
        &:focus {
          color: $font-link-hover;
        }

        &:visited {
          color: $font-link-visited;
        }

        a {
          font-size: 16px;
          margin: 2px 0px;
          font-weight: 400;
          white-space: normal;
        }
      }
    }
  }

  .nav-column-rich-text {
    background-color: $bg-light-gray;
    padding: 40px 15px;
  }

  .mobile-nav-rich-texts {
    white-space: normal;
  }

  .lmig-SlidePanel {
    z-index: 1000;

    &.is-open .lmig-SlidePanel-bg {
      opacity: 0.4;
      visibility: visible;
    }

    .lmig-SlidePanel-panel {
      overflow-y: auto;
      padding: 0px;
      background-color: $bg-light-gray;
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;
      align-items: flex-start;

      .hidden {
        display: none;
      }

      .lm-LogoLibertyMutual--vert {
        order: 0;
        width: 120px;
        padding: 8px;
      }

      .lmig-SlidePanel-close {
        order: 1;
        display: block;
        margin-left: auto;
        position: relative;
      }

      .search-bar {
        order: 2;
        margin: 8px 4px;
        width: 100%;
      }

      .footer-tools {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 16px 16px 32px 16px;
      }

      .mobile-nav-slider {
        order: 3;
        width: 100%;

        .l1-mobile,
        .l2-mobile {
          .mobile-nav-dropdown {
            border-top: 3px solid $border-light-gray;
          }
        }

        .l1-mobile:last-child,
        .l2-mobile:last-child {
          .mobile-nav-dropdown {
            border-bottom: 3px solid $border-light-gray;
          }

          &.show .mobile-nav-dropdown {
            border-bottom: none;
          }
        }

        .mobile-nav-dropdown {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 60px;

          a {
            padding-left: 16px;
            width: 100%;
            font-size: 16px;
            font-weight: 500;
            color: $font-black;
            display: inline-flex;
            align-items: center;
            height: 60px;
            justify-content: space-between;
          }

          .show-caret::after {
            border-right: solid 2px $border-medium-gray;
            content: '';
            height: 100%;
          }

          h6 {
            width: 100%;
            padding-left: 16px;
          }
        }

        .dropdown-caret {
          width: 100%;

          &.lm-Icon-CaretLeft {
            display: none;
          }
        }

        .show {
          > .mobile-nav-dropdown {
            background-color: $bg-medium-gray;

            > a {
              padding-right: 16px;
              padding-left: 0px;
            }

            > .show-caret::before {
              border-right: solid 2px $border-medium-gray;
              content: '';
              height: 100%;
            }

            > .show-caret::after {
              border: none;
            }

            > .lm-Icon-CaretLeft {
              display: block !important;
            }

            > .lm-Icon-CaretRight {
              display: none !important;
            }
          }
        }

        .mobile-section-links {
          display: none;
          flex-direction: column;
          padding: 16px 0px;

          a {
            font-size: 16px;
            padding: 8px 0px 8px 32px;
          }
        }

        .l2-mobile.show,
        .l2-mobile.static {
          .mobile-section-links {
            display: flex;
            border-top: 3px solid $border-light-gray;
          }
        }
      }
    }
  }

  //Breakpoints from largest viewport to smallest
  @media only screen and (min-width: $desktop-min) {
    .lm-masthead {
      .lm-LogoLibertyMutual--horiz {
        display: block;
      }

      .lm-LogoLibertyMutual--vert {
        display: none !important;
      }
    }

    .mobile-navigation-container {
      display: none !important;
    }
  }

  @media only screen and (min-width: $laptop-min) and (max-width: $laptop-max) {
    .lm-masthead {
      .lm-LogoLibertyMutual--horiz {
        display: none !important;
      }

      .lm-LogoLibertyMutual--vert {
        display: block;
      }
    }

    .mobile-navigation-container {
      display: none !important;
    }
  }

  //@media only screen and (min-width: $mobile) and (max-width: $tablet-max) {
  @media only screen and (max-width: $tablet-max) {
    .lm-masthead {
      .lm-LogoLibertyMutual--horiz {
        display: block;
      }

      .lm-LogoLibertyMutual--vert {
        display: none !important;
      }
    }

    .nav-search-wrapper,
    .tools-navigation {
      display: none !important;
    }

    .mobile-navigation {
      display: flex;
    }

    #mobile-quote-modal {
      display: none !important;
    }
  }

  .collapse-nav {
    visibility: hidden;
  }

  .mobile-search-icon-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (max-width: $tablet-max) {
    .mobile-search-icon-container {
      position: relative;
    }
  }

  .mobile-navigation-container {
    display: flex;
    width: 100%;
  }
}

.nav-column {
  padding: 0 10px 0 10px;
}

.floating-header {
  margin-top: 0 !important;
}

.lmig-FloatingHeader-menu-button {
  margin-left: 0px !important;
}

.lmig-FloatingHeader-nav-menu {
  width: 100%;
}

.menu-section-link {
  font-size: 16px;
  padding-bottom: 8px;
}

.nav-section-heading {
  margin-bottom: 6px;
}

.header-actions {
  display: flex;
  align-items: center;
  margin-left: 0px;
  margin-right: 18px;
  p {
    font-size: 14px;
    line-height: 22.4px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 2rem;
    text-wrap: none;
  }
  p:hover {
    text-decoration: underline;
  }
}

#search-text-pl {
  margin-right: 1.5rem;
}

#search-text-bl {
  margin-right: 1.5rem;
}

.lm-Icon-Menu {
  pointer-events: none;
}

.lmig-FloatingHeader-nav {
  order: 0 !important;
}

// desktop
@media only screen and (min-width: 1081px) {
  .mobile-header {
    display: none !important;
  }

  #quote-button-container-mobile {
    display: none;
    justify-content: center;
  }

  .lmig-FloatingHeader-nav-menu #now-quote-modal-wrapper {
    display: none;
  }

  .lmig-FloatingHeader-inner--bg-full--safeco {
    align-items: center;
  }

  .lmig-FloatingHeader-nav {
    order: 0;
  }

  .nav-links-section > .lm-Row {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
  }

  #header-navigation-main {
    height: 100%;
  }

  .lmig-FloatingHeader-dropdown-button {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .lmig-FloatingHeader-nav-menu {
    gap: 0rem !important;
  }
}

#quote-button-container {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

// mobile
@media only screen and (max-width: 1081px) {
  .desktop-header {
    display: none !important;
  }

  .lmig-FloatingHeader-menu-button {
    margin-left: 0px;
  }

  .search-icon-container {
    width: 100%;
  }

  #quote-button-container-mobile {
    display: flex;
    padding-right: 1rem;
    padding-bottom: 8px;
    button {
      width: 100%;
      min-width: calc(100% - 1em);
      margin-right: 2em;
    }
  }

  #quote-button-container {
    display: none !important;
  }

  .header-actions > .search-icon-container {
    display: none;
    margin-left: auto;
  }
  .header-actions {
    margin-left: auto;
    margin-right: 0px;
  }
}

// mobile portrait (e.g. iphone)
@media only screen and (max-width: 479px) {
  .desktop-header {
    display: none !important;
  }

  .lmig-FloatingHeader-menu-button {
    margin-left: 0px;
  }

  .search-icon-container {
    width: 100%;
  }
  .header-actions {
    margin-left: auto;
    margin-right: 0px;
  }
}

.mega-menu-drawer-container {
  display: flex;
  width: 100%;
  padding: 20px 20px 20px 40px;
}

.nav-links-section {
  width: 75%;
  margin-top: -20px;
}

.rich-text-container {
  width: 25%;
  padding: 5px;
}

.rich-text-inner {
  width: 100%;
  height: 100%;
  border-left: 1px solid $border-light-gray;
  padding: 20px;
}

@media only screen and (max-width: 1081px) {
  .mega-menu-drawer-container {
    flex-direction: column;
    padding: 0px;
  }

  .nav-links-section {
    width: 100%;
    padding: 0px;
  }

  .rich-text-container {
    width: 100%;
    padding-top: 10px;
  }

  .rich-text-inner {
    border-top: 1px solid $border-light-gray;
    border-left: 0px;
  }

  .nav-links-section > .lm-Row {
    flex-direction: column !important;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    display: grid;
    grid-auto-columns: 1fr;
  }
}

@media only screen and (min-width: 480px) {
  .mega-menu-wrapper {
    .lmig-Button {
      width: 100%;
      max-width: 100%;
    }
  }
}

#nav-links-section-full-width {
  width: 100%;
}

#mobile-header-search-container {
  display: flex;
  align-items: center;
  p {
    display: none;
  }
}

header {
  #pl-quote-modal > button {
    display: none;
  }
}
#search-input-parent-container .lm-IconButton:before {
  background: transparent !important;
}

.icon-close-search {
  margin-top: 1.25rem;
  margin-right: 0.8rem;
}

#search-button {
  margin-right: 1.37rem;
  margin-top: 0.18rem;
}

.separator-navbar-pl {
  width: 1px;
  height: 56px;
  background-color: $border-light-gray;
  margin-right: 1.5rem;
}
.separator-navbar-bl {
  width: 1px;
  height: 56px;
  background-color: $bg-liberty-blue;
  margin-right: 1.5rem;
}
