//// BREAKPOINTS
@mixin respond-to($breakpoint) {
  @if $breakpoint == 'small' {
    @media (min-width: 320px) {
      @content;
    }
  } @else if $breakpoint == 'medium' {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $breakpoint == 'medium-large' {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $breakpoint == 'large' {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == 'x-large' {
    @media (min-width: 1280px) {
      @content;
    }
  }
}

//// COLORS
$default-black: #343741;
$white-smoke: #f5f5f5;
$dim-gray: #707070;
$whisper: #e6e6e6;
$tealish: #28a3af;
$border-gray: #c0bec0;
// backgrounds
$bg-white: #ffffff;
$bg-sky-blue: #deeffc;
$bg-light-blue: #1283d7;
$bg-royal-blue: #0e66a8;
$bg-dark-blue: #0a4979;

$bg-footer-light-blue: #709fc4;

$bg-light-gray: #f5f5f5;
$bg-medium-gray: #e6e6e6;
$bg-dark-gray: #565656;

$bg-light-orange: #e87413;
$bg-orange: #db7520;

$bg-liberty-blue: #1a1446;

$bg-liberty-yellow: #ffd000;

$bg-warning-text: #fdf1d0;
$bg-warning: #f7b60b;
$bg-error-text: #fef3f5;
$bg-error: #e92243;
$bg-success-text: #e6fff2;
$bg-success: #008040;
$bg-information-text: #effdff;
$bg-information: #037b86;

// fonts
$font-size-heading: 22px;
$font-link: #0e66a8;
$font-link-hover: #1283d7;
$font-link-visited: #0a4979;
$font-light-blue: #709fc4;
$font-medium-blue: #1767a1;
$font-dark-blue: #002663;
$font-link-blue: #06748c;
$font-light-gray: #808080;
$font-light-medium-gray: #707070;
$font-medium-gray: #565656;
$font-dark-gray: #4a4a4a;
$font-orange: #de7520;
$font-dark-orange: #c56621;
$font-pure-white: #f2f3f4;
$font-input-grey: #ababab;
$font-input-black: #323232;
$font-black: #343741;
$font-yellow: #ffd000;
$font-success-green: #03ac63;

// borders
$hr-light-blue: #ecf4fc;
$border-light-blue: #709fc4;
$border-dark-blue: #1767a1;

$border-light-gray: #c0bfc0;
$border-medium-gray: #808080;
$border-dark-gray: #565656;

$border-orange: #de7520;

$border-warning: #f7b60b;
$border-error: #dc1637;
$border-success: #00b539;
$border-information: #28a3af;

//// FONTS
%font-roboto {
  font-family: 'Roboto', sans-serif;
}

%font-roboto-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

%font-roboto-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

%font-awesome {
  font-family: 'FontAwesome';
}

//LAYOUT SPACING
$alert-carousel-v-space: 24px;
$start-page-v-space: 24px;
$start-page-h-space: 24px;
$start-page-link-font-size: 18px;
$start-page-tile-spacing-left: 24px;
$start-page-link-top-spacing: 16px;
