@import '../Shared/Tiles/Tile.scss';

.instant-incentives-tile {
  ul {
    margin: 16px 0px 16px 0px;
    li {
      margin: 16px 0 16px 0px;
    }
  }
  .tile-header {
    .heading-trademark {
      font-size: 14px;
      position: absolute;
      top: -5px;
    }
  }

  .offer-item {
    display: flex;
    justify-content: space-between;
    // uncomment to prevent text wrapping
    // p {
    //   white-space: nowrap;
    //   text-overflow: clip;
    //   overflow: hidden;
    //   width: calc(100% - 50px);
    // }
  }

  .offer-heading {
    border-bottom: 1px solid var(--Primary-Liberty-Blue, #1a1446);
    h6 {
      display: flex;
      align-items: center;
      margin: 0;
      &:before {
        content: '';
        display: inline-flex;
        margin-right: 10px;
      }
    }
  }

  .new-offers h6:before {
    width: 16px;
    height: 18px;
    background-image: url('./newOffer.svg');
  }

  .activated-offers h6:before {
    width: 18px;
    height: 18px;
    background-image: url('./activatedOffer.svg');
  }

  .no-offers-message {
    padding: 16px 0;
    display: flex;
    justify-content: center;
    font-family: 'Roboto Regular';
    font-size: 14px;
    color: #707070;
  }
  .information-updated-text {
    margin-top: auto;
    font-size: 12px;
  }
  .additional-offers {
    font-size: 14px;
    margin-top: auto;
  }
}
