@import 'Styles/GlobalVariables';

#billing-results-lookup-modal-content {
  #policy-lookup-policy-table,
  #policy-lookup-claims-table,
  #summary-lookup-policy-table #billing-table-lookup {
    overflow: auto;
    max-height: 500px;

    thead {
      position: sticky;
      top: 0;
    }
  }
}

.account-summary {
  padding: 3rem;

  .lms-CircularProgressSpinner {
    margin: 2rem 4rem;
  }

  .account-summary-head {
    font-weight: bold;
  }
}

.account-summary > div {
  margin-bottom: 2rem;
}

.account-summary > h3 {
  margin-top: 0;
}

b {
  font-family: Roboto;
}

#customer-lookup-insured-name-table {
  padding: 3rem;
}

.billing-row {
  display: flex;
  padding-bottom: 15px;
  justify-content: space-between;
  width: 100%;
  font-family: 'Roboto';
  border-bottom: 1px solid grey;
  margin-bottom: 15px;

  .billing-label {
    font-weight: bold;
    padding-bottom: 8px;
  }
  &:not(:first-of-type) {
    padding-top: 50px;
  }
}

.billing-table {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  font-family: 'Roboto';

  .billing-column {
    width: 350px;
    margin-left: 50px;

    & > div {
      display: flex;
    }

    .billing-label-inline {
      font-weight: bold;
      margin-right: auto;
    }
  }
}

#policy-lookup-policy-table {
  form {
    .submitLinkStyle {
      border: none;
      background-color: $bg-white;
      font-size: 1em;
      padding: 0px;
    }
    &:hover {
      cursor: pointer;
    }
    input:hover {
      cursor: pointer;
    }
  }
}
