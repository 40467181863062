@import 'Styles/GlobalVariables';

SalesCompUI {
  display: block;
  margin: 16px 16px 32px 16px;

  #search-parameters {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0rem;
    justify-content: space-evenly;

    :last-child {
      justify-content: flex-end;
    }

    .lm-Form {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      > * {
        align-content: center;
        margin-right: 16px;
      }

      .lm-FieldGroup-field {
        margin-bottom: 0px;
      }

      .lm-Field-select {
        height: 100%;
      }

      .agency-search {
        width: 323px;
        height: 50px;
      }

      .agency-comp-search {
        height: 50px;
        margin-top: 0px;
      }

      .time-period-select {
        width: 150px;
      }

      .doc-type-select {
        width: 90px;
      }
    }
  }

  .no-results-message {
    padding-top: 8px;
  }

  #sales-comp-results-table {
    td {
      height: auto !important;
    }
    .lmig-Table-header {
      border: none;

      th {
        font-weight: 700;
      }
    }

    .document-link {
      font-size: 16px;
    }

    #salesCompSpinner {
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
