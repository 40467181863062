@import '~@lmig/lmds-tokens/dist/scss/core/typography';
@import 'Styles/GlobalVariables';

.black-text {
  color: black;
}

.font-24px {
  font-size: 24px;
}

.bold {
  font-weight: $lmig-typography-weight-bold !important;
}

.rich-text ul > li {
  list-style: disc;
}

.rich-text ol > li {
  list-style: decimal;
}

.portal-standalone-link {
  color: $font-link-blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &::after {
    content: url('data:image/svg+xml,<svg viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg" fill="%2306748c"><path d="M1.8848 7.865L1.2818 7.09313L5.5798 3.95438L1.2878 0.90461L1.8798 0.125L6.7128 3.55973C6.8398 3.64969 6.9158 3.79381 6.9168 3.94567C6.9178 4.0985 6.8438 4.24262 6.7178 4.33547L1.8848 7.865Z"></path></svg>');
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-left: 3px;
  }
}
