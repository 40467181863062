.flexible-content-tile-container {
  .portal-tile-v2-body .lmig-Tabs-tabPanel {
    padding-top: 0 !important;
  }
  .renewals-customer-name-container {
    display: flex;
    align-items: center;
    .renwals-icon {
      margin: 0 8px;
    }
  }
  .renewal-change-container {
    display: flex;
    flex-wrap: wrap;
  }
  .renewal-change-amount {
    min-width: 50px;
    text-align: right;
    margin-right: 4px;
    margin-left: auto;
  }
  .renewal-change-percentage {
    min-width: 40px;
    text-align: right;
    margin-left: auto;
    padding-right: 8px;
  }

  #renewals-table-header {
    .small-column-header {
      width: 100px;
    }
    .medium-column-header {
      width: 160px;
    }
  }

  #renewals-table-body {
    .renewals-table-effective-date {
      text-align: left;
      padding-left: 8px !important;
    }
  }

  @media screen and (max-width: 996px) {
    .renewal-change-container {
      justify-content: end;
    }
    .renewal-change-amount {
      margin-right: 0;
    }
    .renewal-change-percentage {
      margin-right: 0;
      padding-right: 0;
    }
  }
  .red-renewal {
    color: #d32f2f;
  }
  .green-renewal {
    color: #008040;
  }
}
