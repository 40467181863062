@import 'Styles/GlobalVariables';

MyInsightsOneThirdTile {
  .portal-tile-v2 {
    height: 436px;
  }
  .portal-tile-v2-header-title-container {
    display: flex !important;
  }

  .portal-tile-v2-body:has(.myinsights-barchart-container) {
    padding: 16px;
    height: 85%;
  }

  .myinsights-one-third-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .myinsights-barchart-container {
      display: flex;
      height: 100%;
      padding-bottom: 4px;
    }

    .myinsights-rich-text-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
    }
  }

  .myinsights-onethird-footer {
    position: absolute;
    left: 16px;
    font-size: 12px;
    bottom: 16px;
  }

  @media screen and (min-width: 769px) {
    .myinsights-rich-text-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
    }
  }

  /* Tablet styles -  On screens that are 992px or less */
  @media screen and (max-width: 991px) {
    .col-xs-8:has(.myinsights-rich-text-container) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    .col-xs-8:has(.myinsights-rich-text-container) {
      width: 100%;
    }

    .myinsightstitle {
      text-align: left;
    }

    .portal-tile-v2-header-title-container {
      padding-top: 0px;
      flex-direction: row;
    }

    .portal-tile-v2 {
      height: auto;
    }
  }
}
