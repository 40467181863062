.mywork-tabs {
  padding-top: 2em;
  .lmig-Popover-overlay.closed {
    display: none;
  }

  iframe {
    width: 100% !important;
    height: 100%;
    border: 0;
  }

  .lmig-Tabs-titleContainer {
    border-bottom: 1px solid var(--lmig-Tabs-panel-border-top-color);
  }

  .lmig-Tabs-tabPanel {
    border-top: 0;
  }

  .lmig-Tabs-tabList {
    button {
      padding-left: 0;

      .lmig-Tabs-tabList-labelVisual {
        left: 0;

        svg:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .mywork-notification {
      margin: 24px 0px;
    }
    .mywork-secondary-search-box {
      padding-right: 0;
      padding-left: 0;
    }
    .lmig-Tabs-titleContainer {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  @media (min-width: 768px) {
    .lmig-Tabs-titleContainer {
      margin-left: 43px;
      margin-right: 43px;
    }
  }
}

.customer-tab-close-icon {
  margin-left: 10px;
}

.mywork-tab-gutter iframe {
  margin-left: 43px;
  min-width: calc(100% - 76px) !important;
  width: calc(100% - 76px) !important;
}

.lmig-Tabs-tabList-labelVisual .lms-CircularProgressSpinner {
  margin-left: 5px;
}

.mywork-tab-title {
  display: flex;
  align-items: center;
}

.mywork-breadcrumb {
  margin-right: 20px;
  z-index: 500;
}

#mywork-advanced-search {
  padding-left: 40px;
  display: flex;
  align-items: center;

  #advanced-search-field-group {
    margin-top: 0;
    width: 100%;
  }

  #advanced-search-popover,
  #mywork-policy-popover,
  #mywork-claims-popover #mywork-billing-popover {
    display: inline-flex;
  }

  .closed {
    #advanced-search-popover {
      display: none;
    }
  }

  #advanced-search-popover .lmig-Popover-overlay {
    width: 500px;

    #advanced-search {
      display: flex;
      flex-wrap: wrap;

      #advanced-search-inputs {
        display: flex;
        width: 100%;

        #first-and-last {
          margin-right: 15px;
          flex-grow: 1;
        }
      }

      #advanced-search-bottom-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: flex-end;
      }
    }
  }

  /* Mobile phone styles -  On screens that are 579px or less */
  @media screen and (max-width: 579px) {
    #advanced-search-popover .lmig-Popover-overlay {
      width: calc(100% - 20px);
      left: -10px !important;

      #advanced-search-inputs {
        flex-wrap: wrap;

        #first-and-last {
          width: 100%;
          margin-right: 0px !important;
        }

        #zip-and-state {
          flex-grow: 1;
          width: 100%;
        }
      }
    }
  }
}

.mywork-customer-links {
  align-items: center;
  display: flex;

  > .lmig-Popover {
    padding-right: 20px;
  }

  .lmig-Popover-overlay {
    width: min-content !important;

    li {
      display: flex;
      padding: 5px;
      align-items: center;

      button,
      a {
        font-size: 18px;
      }
    }
  }

  .mywork-tools-dropdown {
    margin-right: 8px;
  }
}

.mywork-lob-icon {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 0;
  background-color: #ffd000;
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.smart-popover-click-capture-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.mywork-customer-tab-top-row {
  display: flex;
  border-bottom: 1px solid var(--lmig-Tabs-panel-border-top-color);
  margin: -10px 43px 25px 43px;
  padding-bottom: 5px;
  align-items: center;
  #mywork-policy-links,
  #mywork-claim-links,
  #mywork-billing-links {
    z-index: 500;
  }
}
.mywork-notification {
  margin: 24px 43px;
}
.mywork-secondary-search-box {
  padding: 0 43px 20px 43px;
  input {
    padding-left: 30px !important;
  }
}
