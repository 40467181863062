.flexible-content-tile-container {
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    scale: 0.2;
  }
  .spinner {
    position: relative;
    width: 9em;
    height: 12em;
  }
  .spinner:before,
  .spinner:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: inherit;
    height: 12em;
  }
  .spinner:before {
    animation:
      top calc(var(--pl-dur) / 2) linear infinite,
      flipZ var(--pl-dur) steps(1) infinite;
    border-radius: 4.5em 0 0 0 / 4em 0 0 0;
    box-shadow: 0 4em 0 0 inset #ffd000;
    top: 0;
  }
  .spinner:after {
    animation: bottom calc(var(--pl-dur) / 2) linear infinite;
    animation-delay: calc(var(--pl-dur) * -0.25);
    border-radius: 0 0 0 4.5em / 0 0 0 4em;
    box-shadow: 0 -4em 0 0 var(--primary) inset;
    bottom: 0;
  }
  .spinner__sr {
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  /* Animations */
  @keyframes top {
    from {
      animation-timing-function: ease-in;
      clip-path: polygon(0 0, 123% 0, 123% 70%, 123% 70%, 123% 0, 123% 0, 123% 100%, 0 100%);
    }
    50% {
      animation-timing-function: ease-out;
      clip-path: polygon(0 0, 0 0, 0 70%, 77% 70%, 77% 0, 100% 0, 100% 100%, 0 100%);
    }
    to {
      clip-path: polygon(0 0, 0 0, 0 70%, 0 70%, 0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
  @keyframes bottom {
    from {
      animation-timing-function: ease-in;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 30%, 0 30%, 0 100%, 0 100%);
    }
    50% {
      animation-timing-function: ease-out;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 77% 100%, 77% 30%, 0 30%, 0 100%, 0 100%);
    }
    to {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 123% 100%, 123% 30%, 100% 30%, 100% 100%, 0 100%);
    }
  }
  @keyframes flipZ {
    from {
      z-index: 0;
    }
    50%,
    to {
      z-index: 1;
    }
  }
}
