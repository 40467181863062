findanadjuster {
  display: inline-block;
  margin-left: 15px;
  .lm-FieldGroup {
    margin-top: 2rem !important;
  }
}

.inline-form {
  display: flex;
  .lm-FieldGroup-field--input {
    margin-bottom: -2px; //offset box shadow on buttons so everything perfectly aligned
  }
}
.scrollable-table-head,
.scrollable-table-body {
  width: 900px;
  display: flex;
  tr {
    display: flex;
    width: 100%;
    td,
    th {
      display: inline-block;
    }
    th:last-child {
      padding-left: 5px;
    }
  }
}
.scrollable-table-head tr {
  display: flex;
  align-items: center;
}
.scrollable-table-body {
  max-height: 600px;
  overflow-y: auto;
  flex-direction: column;
}

.no-button-margin {
  margin-left: 0px !important;
  margin-top: 0px !important;
}

body {
  color: #343741;
}
