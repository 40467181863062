@import 'Styles/GlobalVariables';

.portal-tile-v2-header-title-container {
  display: flex !important;
}

.myinsights-barchart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  max-height: 300px;
}

.myinsightstitle {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
}

.myinsights-barchart {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
}

.portal-tile-v2-body:has(.myinsights-container) {
  padding-bottom: 10px;
}

.myinsights-container {
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 769px) {
  .portal-tile-v2:has(.myinsights-columns-container) {
    border-color: #ffd000;
    padding-bottom: 0px;
  }

  .myinsights-columns-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  .myinsights-left-column-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 25px;
    width: 50%;
    height: 100%;
    border: none;
  }

  .myinsights-right-column-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding-left: 10px;
    transform: translateY(-50px);
    overflow: unset;
  }
}

/* Tablet styles -  On screens that are 992px or less */
@media screen and (max-width: 991px) {
  .col-xs-8:has(.myinsights-columns-container) {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .portal-tile-v2:has(.myinsights-columns-container) {
    border-color: #ffd000;
    padding-bottom: 0px;
  }

  .col-xs-8:has(.myinsights-columns-container) {
    width: 100%;
  }

  .myinsights-columns-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .myinsights-left-column-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    border: none;
  }

  .myinsightstitle {
    text-align: left;
  }

  .myinsights-right-column-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.myinsights-footer {
  position: absolute;
  left: 16px;
  font-size: 12px;
  bottom: 16px;
}
