@import './GlobalVariables.scss';

manageblportalusers {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .agency-info-search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 8px 0px;
    align-self: flex-start;

    .agency-info {
      margin: 0px 0px 8px 4px;

      h6 {
        margin: 0;
      }
    }

    .agency-portal-users-search {
      display: flex;

      .lm-Form {
        display: flex;
        font-family: inherit;
        margin: inherit;
        max-width: inherit;
      }

      .lm-FieldGroup {
        display: flex;
        flex-direction: column;
        margin: 0px;
        width: 260px;
        justify-content: flex-end;
      }

      .agency-users-search {
        margin: 0px 0px 8px 16px;
        height: 50px;
        align-self: flex-end;
        width: 180px;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .manage-portal-users-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .create-user-export-data {
      display: flex;
      justify-content: space-between;
      width: 100%;

      > div {
        margin: 0px 8px;
        color: $font-link-blue;
        align-items: center;
        display: flex;
        width: fit-content;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
          color: $font-link-hover;
        }

        svg {
          margin: 0px 4px;
        }
      }

      .create-portal-user {
        svg {
          margin: 0px 4px 2px 0px;
        }
      }

      .export-button {
        svg {
          margin: 0px 4px 4px 0px;
        }
      }
    }

    .portal-users-loading-spinner {
      margin: 16px 0px;
    }

    .disabling-spinner {
      margin-left: 1.5em;
    }

    .disable-helper-text {
      align-self: flex-start;
    }

    .lmig-Notification {
      width: 100%;
      margin-top: 1em;
    }
  }

  .manage-portal-users-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .lmig-Table-wrapper {
      width: 100%;
    }

    .manage-portal-users-table {
      th {
        font-weight: 500;
      }

      .lmig-Table-header {
        border-top: none;
      }

      .disable-user-button {
        .lmig-Table-cell-contentButton {
          color: $font-link-blue;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
            color: $font-link-hover;
          }
        }
      }

      .role-drop-down select {
        padding-top: 10px !important;
      }
    }

    .table-pagination {
      align-self: flex-end;
    }
  }

  .no-users-text {
    width: 456px;
    text-align: left;
  }

  .no-authorization {
    max-width: 700px;
  }
}

.access-code-link {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  h3 {
    margin: 0;
  }

  & > svg {
    margin-left: 1em;
  }

  .lmig-Badge {
    margin-left: auto;
  }
}

.disable-user-modal .lmig-Button,
.create-new-user-modal .lmig-Button {
  margin-left: 0;
}
