@import './Common/Spacing.scss';
@import './Common/Layout.scss';
@import './Common/Text.scss';
@import '~@lmig/lmds-tokens/dist/scss/core/color';
@import '~@lmig/lmds-tokens/dist/scss/core/typography';

body {
  font-size: $lmig-typography-size-rem-default;
  font-weight: $lmig-typography-weight-regular;
}

.select-no-label {
  select {
    height: 40px !important;
    padding: 6px !important;
  }
  svg {
    top: 12px !important;
  }
}

.standard-font {
  color: $lmig-color-text-default;
  font-weight: $lmig-typography-weight-regular;
  font-size: $lmig-typography-h6-default-fontsize-desktop;
}

.force-show-body {
  display: block;
}
.lmig-Tabs-dropdown-select {
  color: black !important;
}
