@import 'Styles/GlobalVariables';

.slider-panel,
.favorites-page {
  width: 100%;
  .favorites-section .lm-Heading5 {
    border-bottom: 1px solid $border-light-gray;
  }
}

.favorites-link {
  display: flex;
  max-width: 251px;
  .lm-Icon-RatingsStarUnfilled,
  .lm-Icon-RatingsStarFilled {
    margin-top: 2px;
    margin-right: 8px;
    width: 16px;
    min-width: 16px;
    &:hover {
      cursor: pointer;
    }
  }
  a {
    font-size: 16px;
  }
}

.favorites-page {
  .favorites-link,
  h5 {
    max-width: 100%;
  }
}

.full-page-view-link {
  justify-content: flex-end;
  font-size: 16px;
  align-items: center;
  margin-left: auto;
}
