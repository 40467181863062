@import 'Styles/GlobalVariables';

.tooltip-wrapper {
  opacity: 1;
  visibility: visible;
  position: relative;
  display: flex;

  &:hover {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }

  .tooltiptext {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -35px;
    left: -62%;
    background-color: $dim-gray;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    border-radius: 6px;
    padding: 4px 12px;
    width: max-content;
    text-align: center;
    transition: 0.25s all ease;
    transition-delay: 0.4s;
    pointer-events: none;
  }
}
