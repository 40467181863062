/*reset toast styles that are loaded as @layer */
.lmig-ToastContainer:has(.lmig-Toast) {
  padding: 24px;
  z-index: 1000;
}
.lmig-Toast--caution {
  border-left-color: #b85d00 !important;
}
.lmig-Toast--positive {
  border-left-color: #008040 !important;
}
.lmig-Toast {
  padding: 11px 44px 11px 12px;
  border-radius: 6px;
  border-bottom: 1px solid rgb(230, 230, 230);
  border-left: 6px solid rgb(184, 93, 0);
  border-right: 1px solid rgb(230, 230, 230);
  border-top: 1px solid rgb(230, 230, 230);
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }

  .lmig-Toast-supportingContent {
    margin: 8px 0 0 24px;
  }
}
