@import 'Styles/GlobalVariables';

#agency-code-selector {
  position: relative;

  button.agency-code-disabled {
    text-decoration: none !important;
    cursor: default;
  }

  .lmig-Popover-overlay {
    max-height: 75vh;
  }

  .lmig-Popover .closed {
    display: none;
  }

  .lmig-Popover button:before,
  .lmig-Popover button:after {
    display: none;
  }

  #selected-agency-code {
    padding: 0;
    flex-wrap: nowrap;
    display: flex;

    svg {
      margin-left: 10px;
      position: relative;
      left: unset;
      top: unset;
    }
  }

  #agency-code-dropdown {
    border-right: none;
    padding: 15px 0px;

    .lm-FieldGroup-wrapper,
    input {
      outline: none !important;
    }

    .lm-FieldGroup-wrapper {
      border-right: none;
      padding: 0px;
      padding-bottom: 5px;
      .lm-FieldGroup-field,
      .lm-FieldGroup-inner {
        padding: 0px;
        button {
          top: 2px;
          right: 20px;
        }
        .lm-Field-input-icon {
          height: 16px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0nbG0tSWNvbiBsbS1JY29uLUZpbHRlcicgYXJpYS1sYWJlbD0nSWNvbkZpbHRlcicgd2lkdGg9JzE2JyBoZWlnaHQ9JzE2JyB2aWV3Qm94PScwIDAgMzIgMzInIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycgZm9jdXNhYmxlPSdmYWxzZScgcm9sZT0naW1nJyBsYWJlbD0nRmlsdGVyJz48cGF0aCBkPSdNMjkuMjI1LjQyOEEuNzUuNzUgMCAwIDAgMjguNTQ4IDBoLTUuMDN2MS41MDNoMy40NTdsLTkuNzI4IDEyLjAzYS43NTYuNzU2IDAgMCAwLS4xNjguNDcybC0uMDI1IDEzLjIxOC0zLjAwOSAyLjQ0OFYxMy45NjNhLjc1Ljc1IDAgMCAwLS4xNjQtLjQ2OUw0LjMxMSAxLjUwM2gxNi44MTdWMEgyLjc1YS43NS43NSAwIDAgMC0uNTg2IDEuMjJsMTAuMzgxIDEzLjAwOHYxNy4wMmEuNzUzLjc1MyAwIDAgMCAuNzUuNzUyLjc0Ny43NDcgMCAwIDAgLjQ3My0uMTY4bDQuNTA4LTMuNjY3YS43NS43NSAwIDAgMCAuMjc4LS41ODNsLjAyNS0xMy4zMUwyOS4xMzEgMS4yMjVhLjc1Mi43NTIgMCAwIDAgLjA5NC0uNzk2Jz48L3BhdGg+PHJlY3Qgd2lkdGg9JzEwMCUnIGhlaWdodD0nMTAwJScgc3R5bGU9J29wYWNpdHk6IDA7Jz48dGl0bGU+SWNvbkZpbHRlcjwvdGl0bGU+PC9yZWN0Pjwvc3ZnPg==);
          width: 16px;
          svg {
            display: none;
          }
        }
      }
    }

    & > ul {
      max-height: calc(75vh - 140px);
      overflow-y: auto;
    }
    .no-matching-agency-codes {
      padding-top: 15px;
      padding-left: 2px;
    }
    p {
      padding-bottom: 10px;
      font-size: 12px;
    }

    .agency-code {
      color: $font-link-blue;
      padding: 8px 0px;
      .master-code {
        display: flex;
        align-items: center;
        border-right: 0;
      }

      .master-code:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      .agency-codes-list {
        padding-top: 5px;
      }
    }
  }
}
