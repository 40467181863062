@import 'Styles/GlobalVariables';

.image-rich-text-tile {
  background-color: white;
  border: solid 1px #f5f5f5;
  border-radius: 8px;
  height: 436px;
  width: 100%;

  .image-rich-text-tile-body {
    display: flex;
    font-size: 18px !important;
    justify-content: center;
    padding: 16px;

    a {
      color: $font-link-blue !important;
      font-size: 18px !important;
      text-decoration: none !important;
      &:hover {
        color: $font-link-blue !important;
        text-decoration: underline !important;
      }
    }

    li {
      list-style: inside;
    }
  }

  strong {
    font-weight: bold !important;
  }
}

.image-rich-text-full-width {
  width: 100%;
}

.image-rich-text-tile-header {
  display: flex;
  height: 10%;
  width: 100%;
  padding-bottom: 0px;
  padding-top: 10px;
  padding-left: 16px;
  padding-right: 4px;
  margin: 0px;
}

.image-rich-text-tile-header-title-container {
  text-align: left !important;
  display: flex;
  font-size: 24px;
  align-items: baseline;
  grid-gap: 5px;
  text-align: left !important;
  padding-left: 0px;

  .custom-tile-heading-margin {
    margin: 0;
  }
}

imagerichtexttile {
  display: block;
}
