@use 'sass:math';

@use 'sass:map';
@import './_tokens';

@mixin lmig-base-font {
  font-family: Roboto, Arial, sans-serif;
}

@mixin lmig-inherit-font-styles {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  line-height: inherit;
}

@mixin lmig-a11y-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

@mixin lmig-dynamicWidth {
  width: 100%;
  max-width: none;
}

@mixin lmig-box-sizing {
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }
}

// Margin and padding mixins for avoiding accidental resets
// https://css-tricks.com/accidental-css-resets/

@mixin lmig-margin-horizontal($size) {
  margin-right: $size;
  margin-left: $size;
}

@mixin lmig-margin-vertical($size) {
  margin-top: $size;
  margin-bottom: $size;
}

@mixin lmig-padding-horizontal($size) {
  padding-right: $size;
  padding-left: $size;
}

@mixin lmig-padding-vertical($size) {
  padding-top: $size;
  padding-bottom: $size;
}

// TODO: Align with LMDS breakpoint mixins

@mixin breakpoint-base {
  @media (max-width: ($lmig-breakpoint-sm-min - 1)) {
    @content;
  }
}

@mixin breakpoint-sm {
  @media (min-width: $lmig-breakpoint-sm-min) {
    @content;
  }
}

@mixin breakpoint-md {
  @media (min-width: $lmig-breakpoint-md-min) {
    @content;
  }
}

@mixin breakpoint($min: 0, $max: null) {
  $minBps: (
    'base': $lmig-breakpoint-base-min,
    'sm': $lmig-breakpoint-sm-min,
    'md': $lmig-breakpoint-md-min,
    'lg': $lmig-breakpoint-lg-min,
    'xl': $lmig-breakpoint-xl-min,
  );
  $maxBps: (
    'sm': $lmig-breakpoint-base-max,
    'md': $lmig-breakpoint-sm-max,
    'lg': $lmig-breakpoint-md-max,
    'xl': $lmig-breakpoint-lg-max,
  );
  $minBp: map.get($minBps, $min);
  $maxBp: map.get($maxBps, $max);
  $minPx: if($minBp, $minBp, $min);
  $maxPx: if($maxBp, $maxBp, $max);

  @if not $max {
    @media (min-width: #{$minPx}) {
      @content;
    }
  } @else {
    @media (min-width: #{$minPx}) and (max-width: #{$maxPx}) {
      @content;
    }
  }
}

// functions
@function lmig-convert-to-rem($pixel) {
  @return math.div($pixel, 16px) * 1rem;
}

@function lmig-convert-to-px($rem) {
  @return ($rem * 16) * 1px;
}

/// Adds CSS variables for custom theme. Keeping this in _mixins to avoid breaking change.
/// @group theme
/// @param {string} $selector - the selector you want to target to scope your variables to. (e.g., .lmig-Button)
/// @param {string} $theme [dark] -  which theme you want to scope the variables for. Defaults to 'dark'.
@mixin lmig-package-theme-css-variables($selector: '', $theme: 'dark') {
  @if $selector == '' {
    @error 'Please include a selector.';
  }

  [data-theme*='#{$theme}'] #{$selector} {
    color-scheme: dark;
    @content;
  }
}
