.login-error-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .login-error-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: auto;
    min-height: 520px;
    .login-error-help {
      margin-left: 20px;
      margin-right: 20px;
    }
    .login-error-message {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 350px;
      margin-bottom: 50px;
      text-align: center;
    }

    .login-error-tiles {
      display: flex;

      .login-error-tile {
        display: flex;
        border: 1px solid #c0bfc0;
        border-radius: 8px;
        padding: 15px 30px;
        max-width: 450px;

        svg {
          margin-right: 20px;
          margin-top: 5px;
        }

        &:first-child {
          margin-right: 15px;
        }
      }
    }

    .login-error-subtext {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .login-error-links {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      margin-bottom: 40px;
      margin-left: 20px;
      margin-top: 20px;
      h4 {
        border-bottom: 1px solid #c0bfc0;
        padding-bottom: 20px;
      }
      .login-error-links-columns {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        div:first-child {
          padding-right: 40px;
        }
        a {
          padding: 5px 0px;
        }
      }
    }
  }

  /* Tablet phone styles -  On screens that are 768px or less */
  @media screen and (max-width: 768px) {
    .login-error-help {
      margin-left: 10%;
      margin-right: 10%;

      .login-error-tiles {
        flex-wrap: wrap;
        justify-content: center;

        .login-error-tile {
          width: 100%;
          max-width: unset;
        }

        .login-error-tile:first-child {
          margin-right: 0px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
