@import '~@lmig/lmds-tokens/dist/scss/core/color';
.flex-grow-1 {
  flex-grow: 1;
}

.align-content-middle {
  display: flex;
  align-items: center;
}

.container-gray-background {
  background-color: $lmig-color-palette-gray04 !important;
}

.uscm-flexbox {
  display: flex;
}

.uscm-center-align {
  align-items: center;
}

.uscm-full-width {
  width: 100%;
}
