@import '../RecentActivitiesTable.scss';

.lmig-Table-cell-sortButton:has(.billing-amountdue-header) {
  justify-content: flex-end;
}

#billing-table-header {
  .small-column-header {
    width: 135px;
  }
}

.billing-date-column {
  padding-left: 8px;
}
.billing-amount-due-column {
  text-align: right;
  padding-right: 8px;
}
.billing-customer-column {
  padding-left: 8px;
}
