@import 'Styles/GlobalVariables';

.recent-activities-no-data-right {
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 479px) {
  .recent-activities-no-data-right {
    margin-top: 24px;
  }
}
