@import 'Styles/GlobalVariables';

.horizontal-bar-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .chart-title {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 16px;
  }

  .bar-chart-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  .bar-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 4px;

    .bar-label {
      color: $font-black;
      font-size: 14px;
    }

    .bar-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .bar-background {
        background-color: $white-smoke;
        border-radius: 5px;
        height: 20px;
        flex-grow: 2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .bar {
        height: 100%;
        border-radius: inherit;
      }

      .bar-value {
        color: $font-black;
        font-size: 16px;
        width: 40px;
        text-align: right;
      }
    }
  }
}
