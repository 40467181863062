@import '~@lmig/lmds-tokens/dist/scss/core/color';
@import 'Styles/GlobalVariables';

newsstarttile {
  width: 100%;

  #news-start-tile-container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: white;
    border: solid 1px $border-light-gray;
    border-radius: 8px;
    height: 436px;

    .header {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;

      .title {
        margin: 0px;
      }
    }

    .latest-news-articles {
      margin-top: 16px;
      overflow-y: hidden;

      .news-article-link {
        display: inline-flex;
        align-items: center;
        height: 60px;

        img {
          margin-right: 16px;
          height: 56px;
        }

        div {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 3px;
        }
      }

      hr {
        margin-top: 10px;
        margin-bottom: 16px;
      }
    }

    .no-latest-articles {
      margin: 24px 0px;
    }
  }
}
