@import 'Styles/GlobalVariables';

.billing-summary-container {
  border: 1px solid #ffd000;
  border-radius: 4px;
  height: 115px !important;
  margin-top: 16px;
  margin-right: 48px;
  padding: 16px;
  text-align: center;
  width: 139px !important;
}
.billing-summary-dollar-amount {
  font-size: 24px;
  line-height: 28.8px;
}
.billing-summary-policy-count {
  font-size: 12px;
}
.billing-summary-text {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .portal-tile-v2:has(.billing-summary-container) {
    height: auto;
    padding-bottom: 25px;
  }
  .flexible-content-tile-container:has(.billing-summary-container) {
    display: grid;
    margin-bottom: 12px;
    margin-top: 12px;

    .tile-child:nth-child(1) {
      width: 100% !important;
    }
    .tile-child:nth-child(2) {
      width: 100% !important;
      scale: 1;
    }
    .billing-tablet-size {
      align-items: center;
      border: unset !important;
      display: flex;
      flex-direction: row;
      height: 61px !important;
      justify-content: center;
      margin-top: unset;
      padding: 16px;
      text-align: center;
      width: 100% !important;
    }
    > div:first-child {
      > div:first-child {
        border: 1px solid #ffd000;
        border-radius: 4px;
        height: unset;
        width: 100%;
      }
      > div:nth-child(2) {
        display: none !important;
      }
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .flexible-content-tile-container:has(.billing-summary-container) {
    .tile-child:nth-child(1) {
      width: 100% !important;
      display: flex;
      align-items: center;
      > div.flexcontenttile-first-child-container {
        justify-content: center;
        padding: 16px 16px 12px 16px;
        height: 115px !important;
        width: 50% !important;
      }
    }
    .tile-child:nth-child(2) {
      width: 100% !important;
    }
  }
  .billing-phone-size {
    padding: unset !important;
    flex-direction: column !important;
  }
}

@media screen and (max-width: 440px) {
  .flexible-content-tile-container:has(.billing-summary-container) {
    display: unset !important;
    .tile-child:first-child {
      padding: 16px 0px 12px 0px;
    }
  }
}
