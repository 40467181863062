@import 'Styles/GlobalVariables';

AgencySweepReport {
  .agency-sweep-report {
    border: 1px solid $border-gray;
    border-radius: 3px;
    text-align: center;
    width: 100%;
    margin: 1rem;
    display: flex;
    flex-direction: column;

    .alert-message {
      margin: 1rem 1.5rem 0;
    }

    .search-header {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin: 1rem 1rem 0rem 1rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $border-gray;
      justify-content: space-evenly;

      h4 {
        padding-bottom: 0.5rem;
      }

      .search-terms {
        form {
          display: flex;
          flex-direction: row;
          align-items: center;
          //flex-wrap: wrap;
          > * {
            margin: 0rem 1rem 1rem 0rem;
            align-content: center;
          }
          .date-field {
            //width: 80%;
            margin-top: 2px;
          }
          .offset-text-field {
            //margin-top: 20px;
            margin-bottom: -0.5rem;
          }
          .MuiFormHelperText-contained {
            font-style: italic;
          }
        }
      }
    }

    .print-export-functions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-right: 1rem;

      button {
        border: none;
        background-color: white;
        margin: 0.5rem 0rem 0.5rem 1rem;
        cursor: pointer;
        .lm-Icon {
          max-width: 24px;
          margin-right: 0.5rem;
        }
      }

      .disabledBtnIcon {
        opacity: 0.6;
        cursor: default;
      }
    }

    .agency-sweep-results {
      text-align: left;

      td:first-child,
      th:first-child {
        padding-left: 32px;
      }
    }

    .no-data-message {
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
    }
  }
}

/* print styles */
@media print {
  header {
    display: none;
  }

  #now-navbar {
    display: none;
  }

  #stat-selector {
    display: none;
  }

  .now-main-menu-selector-wrapper {
    display: none;
  }

  .breadcrumb-item.last.home {
    display: none;
  }

  footer {
    display: none;
  }
}

@page {
  size: B4 (JIS);
  margin: 0;
}
