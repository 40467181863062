@import '~@lmig/lmds-tokens/dist/scss/core/color';
@import 'Styles/GlobalVariables.scss';

globalstatedropdown {
  display: flex;
}

.global-state-selector-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  height: 100%;
  width: 100%;

  + .global-state-dropdown {
    z-index: 2000;
  }
}

.bl {
  .dropdown-menu-content:hover {
    background-color: white;
  }
}

.bl,
.pl {
  &.global-state-dropdown {
    display: flex;
    position: relative;
    align-items: center;

    .state-dropdown-toggle {
      font-weight: 400;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 1;
      border: none;
      min-width: 65px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      &.open {
        color: $lmig-color-palette-libertyBlue;
        background-color: $lmig-color-background-white;
        + .state-specific-dropdown-menu {
          display: flex;
          line-height: 1;
        }
      }

      svg {
        margin-left: 8px;
      }
    }
    &.open .dropdown-menu {
      display: flex;
    }
  }

  .state-specific-dropdown-menu {
    top: 24px;
    left: 16px;
    z-index: 13;
    padding: 0;
    border: none;
    position: absolute;
    top: 100%;

    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    &.show {
      display: block;
    }
    .icon-up {
      position: absolute;
      left: 33px;
      top: -7px;
      fill: white;
      filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.1));
    }

    .dropdown-menu-content {
      box-shadow: 5px 4px 12px 10px rgba(0, 0, 0, 0.1);
      padding: 15px 0px;
      border: none;
      max-height: 70vh;
      overflow-y: auto;

      .dropdown-item {
        display: block;
        width: 100%;
        padding: 0.25rem 1.5rem;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
      }

      .dropdown-states {
        padding: 0px 16px;

        .state-specific-selector-state-text {
          padding: 4px;
          white-space: nowrap;
          border: none;
          color: $font-link-blue;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .show {
    .state-dropdown-toggle {
      border: 1px solid $lmig-color-logo-liberty-black !important;
    }

    .dropdown-menu-content {
      display: flex !important;
    }
  }

  .dropdown-menu-leading-text {
    display: none;
  }
}
