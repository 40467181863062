@import 'Styles/GlobalVariables';

flexibletwotabtile {
  .flexible-two-tab-tile {
    display: flex;
    width: 100%;
    height: 436px;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;

    .portal-tile-v2-body {
      padding: 0 16px 24px 16px;
    }

    .lmig-Tabs.lmig-Tabs--compact {
      padding-top: 0;
    }

    .portal-tile-v2-wrapper {
      width: 100%;

      .portal-tile-v2 {
        .portal-tile-v2-header {
          align-items: center;
        }
      }
    }
  }
}
