@mixin lmig-FloatingHeader-item() {
  $lmig-item-display-none: none;

  display: flex;
  list-style: none;

  // this is to expand all nav items to the full height, so it has a larger click area.
  // this will need to be reverted back to initial when the overlay is displayed.
  &--nav {
    line-height: var(--lmig-FloatingHeader-height);
  }

  // actions are aligned to the center because they will most likely be buttons which have their own size.
  &--actions {
    align-items: center;
  }

  &-hidden {
    display: $lmig-item-display-none;
  }
}
