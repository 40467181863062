blhelprequestform {
  width: 100%;

  #bl-help-request-form {
    font-size: 18px;
    font-weight: 300;

    .bl-help-request-form-bold {
      font-size: 16px;
      font-weight: 500;
    }

    .mail-to-link {
      display: flex;
      width: fit-content;
      height: fit-content;
      margin-top: 1rem;
    }

    .form-box {
      border: 1px solid lightgray;
      border-radius: 2px;
      padding: 24px;

      &.top {
        margin-bottom: 1.5rem;
      }
    }

    .form-top-section {
      .sub-header {
        margin-bottom: 0.25rem;
      }

      .contact {
        display: flex;
        flex-wrap: wrap;

        .info {
          width: 350px;
          max-width: 100%;
          overflow: overlay;

          &.first-column {
            margin-right: 1rem;
          }

          .group {
            margin-bottom: 1rem;
          }

          .phone {
            margin-bottom: 0.5rem;
          }
          .email {
            margin-bottom: 0.5rem;
          }
        }

        .icon {
          margin-right: 10px;
        }
      }
    }

    .add-bottom-margin {
      margin-bottom: 1.25rem;
    }

    #bl-help-request-fieldgroup {
      margin-top: 1rem;
      width: 350px;
      max-width: 100%;
    }

    #Textarea--default {
      width: 700px;
      max-width: 100%;
    }

    .bottom-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
