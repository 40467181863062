.now-row-no-padding {
  > .row {
    padding-left: 0px !important;
  }
}

.now-no-margins,
.iaportals-no-margins,
.no-margins {
  margin: 0px !important;

  .component {
    margin: 0px !important;
  }
}

.no-padding {
  padding: 0 !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pl-6 {
  padding-left: 6px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-56 {
  padding-bottom: 56px !important;
}
