#safeco-settings-page {
  .lmig-FloatingHeader-inner--safeco,
  .lmig-FloatingHeader-brand {
    background-color: #003d7b !important;
  }

  #iSettings {
    border-left: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    margin-top: 15px;
  }

  .lm-Footer {
    padding-top: 24px !important;
    padding-bottom: 24px;
  }
}
