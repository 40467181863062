.customer-account-lookup {
  svg {
    width: 24px;
  }
  input {
    &::placholder {
      color: rgb(112, 112, 112);
    }
    width: 100%;
    margin-bottom: 10px;
    height: 50px;
    border-radius: 3px;
    border: 1px solid rgb(145, 145, 145);
    padding: 8px;
    &:focus-visible {
      outline: none;
      font-weight: light;
    }
  }
}

.slider-panel #customer-account-lookup-form-policy .lm-FieldGroup,
.slider-panel #customer-account-lookup-form-customers .lm-FieldGroup {
  margin-top: 0px;
}

.slider-panel #customer-account-lookup-tabs-tab0,
.slider-panel #customer-account-lookup-tabs-tab1 {
  margin: 0px;
}

.slider-panel .lmig-Tabs-tabList {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.slider-panel #customer-account-lookup-tabs {
  padding: 1rem;
  margin-bottom: 1em;
  button {
    margin: 0;
  }
}

.customer-account-lookup-helper-text {
  padding-bottom: 15px;
  span,
  p,
  a {
    font-size: 14px;
  }
}
