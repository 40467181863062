@import 'Styles/GlobalVariables';

recentactivitiestile {
  display: flex;
  height: 100%;
}

.recent-activities-wrapper {
  width: 100%;
}

.lmig-Tabs-tabPanel:has(#renewals-table-container, #billing-table-container, #policies-table-container) {
  padding-top: 0;
}
.flexible-content-tile-container {
  .recent-activities-table-container {
    .lmig-Table-cell--align-right .lmig-Table-cell-contentButton,
    .lmig-Table-cell--align-right .lmig-Table-cell-sortButton {
      flex-flow: row-reverse;
    }
    margin-top: 12px;
    padding-bottom: 80px;
    min-height: 308px;
    max-height: 308px;
    margin-left: 10px;
    .recent-activities-table-header {
      border-top: none;
      font-weight: 700;
    }
    td {
      padding: 4px 0 0 0 !important;
      height: 35px !important;
    }
    th {
      font-weight: 700 !important;
    }
    nav {
      height: unset !important;
    }
    a {
      font-size: 12px !important;
    }

    .recent-activities-info-view-all {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;

      .recent-activities-info {
        display: flex;
        align-items: center;
        height: 26px;
        font-weight: 700;
        font-size: 12px;
        line-height: 25.6px;
        color: #0061f5;
        justify-content: flex-start;
        width: 100%;
        a {
          margin-left: auto;
          font-size: 12px !important;
        }
        .lm-Icon {
          margin-right: 6px;
        }
      }
    }
  }

  @media screen and (max-width: 479px) {
    .recent-activities-table-container {
      padding-bottom: 10px;
    }
  }

  .portal-tile-v2-body .lmig-Tabs-tabPanel {
    padding-top: 0 !important;
  }
  :root {
    --hue: 223;
    --bg: #ffd000;
    --fg: #ffd000;
    --primary: #1a1446;
    --trans-dur: 0.3s;
    --pl-dur: 2.6s;
    font-size: calc(16px + (20 - 16) * (100vw - 320px) / (1280 - 320));
  }
  .recent-activities-pagination {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .recent-activities-pagination * {
    scale: 0.9;
  }
  .lmig-Pagination-nav-pages {
    flex-flow: unset;
  }
  .lmig-Pagination-nav-pages--highlight {
    display: none;
  }
  .lmig-Pagination-nav-pages-button {
    transition: all 0.5s ease-in-out;
  }
  .lmig-Pagination-nav-pages-button::after {
    content: '';
    background-color: none;
    height: 5px;
    width: 0;
    position: absolute;
    inset: 0;
    top: 100%;
    transition: all 0.5s ease-in-out;
  }
  .lmig-Pagination-nav-pages-button.is-active::after {
    content: '';
    background-color: $font-link-blue;
    height: 5px;
    width: 100%;
    position: absolute;
    inset: 0;
    top: 100%;
  }
}

@media (max-width: 479px) {
  .policy-transactions-graphic > div {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 380px) {
  .flexible-content-tile-container .lmig-Pagination-nav-pages {
    left: 30px;
  }
}

@media (max-width: 354px) {
  .flexible-content-tile-container .lmig-Pagination-nav-pages {
    left: 50px;
  }
}
