@import 'Styles/GlobalVariables';

contentmodal {
  display: block;
}

.on-page-editor .lmig-Modal .lmig-Modal-container {
  top: 25%;
}

.lmig-Modal .component {
  z-index: 500;
}

.content-modal {
  width: 100%;
  padding-top: 0px;
}

.content-modal .lmig-Modal-container {
  min-width: 200px;
  max-width: max-content;
  margin: 10vh auto;

  .show > div {
    display: flex !important;
  }

  .lmig-Modal-body {
    margin: 0px;

    .plain-html {
      padding: 0;
    }

    .row {
      min-width: 175px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .lmig-Modal-footer {
    margin: 0px;
  }

  .lmig-ButtonClose {
    margin-right: 1rem;
  }
}

.lmig-Modal--fit .lmig-Modal-container {
  max-width: max-content;
  max-height: max-content;
}

.content-modal.lmig-Modal.lmig-Modal--size-small .lmig-Modal-container,
.content-modal.lmig-Modal.lmig-Modal--size-medium .lmig-Modal-container,
.content-modal.lmig-Modal.lmig-Modal--size-large .lmig-Modal-container {
  max-width: max-content;
}

code .content-modal {
  .lmig-Modal.lmig-Modal--small .lmig-Modal-container .lmig-Modal-body .hidden {
    width: 100%;
  }
}

.modal-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;

  .lmig-FieldGroup-field--checkbox {
    margin-bottom: 0px;
  }
}

.clickable-image {
  cursor: pointer;
  box-sizing: content-box;
}
