$settings-text-break: 410px;

pageheader {
  width: 100%;
}

.page-header-utility {
  display: flex;
  align-items: center;
  padding: 12px 0px 12px 0px;
  z-index: 1000;
  > :not(:last-child) {
    padding-right: 8px;
  }

  div,
  a {
    line-height: 1;
    font-size: 1rem;
    padding: 0px 8px;

    &:not(:last-child) {
      border-right: 1px solid black;
    }
  }

  .lmig-Alert-icon {
    margin-top: 0px !important;
  }

  .settings {
    display: flex;
    align-items: center;
    margin: 0px 8px;
    padding-right: 16px;

    .settings-text {
      padding-left: 8px;
    }

    @media only screen and (max-width: $settings-text-break) {
      .settings-text {
        display: none;
      }
    }
  }

  .logout-link {
    white-space: nowrap;
  }
}

#page-header-container {
  margin: 0 10px 10px 10px;
}

#page-header-inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5px;
}

.lmig-FloatingHeader-brand {
  flex: 0 0 221px;
  svg {
    min-width: 157px;
  }
}
