.piechart-legend-title {
  font-size: 15px !important;
}

.recharts-legend-item-text {
  color: black !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 22.4px;
}

.recharts-default-legend {
  display: flex;
  flex-wrap: wrap;
  width: 200px;
}

.custom-legend-title {
  font-size: 14px;
  height: auto;
  left: 25px;
  position: absolute;
  width: 180px;
  bottom: 80px;
}

.recharts-legend-wrapper {
  bottom: 27px !important;
  left: 25px !important;
}

.recharts-wrapper {
  min-height: 300px;
}

.recharts-tooltip-wrapper {
  width: 128px;
  height: 159px;
  .recharts-default-tooltip {
    padding: 16px;
    font-size: 14px;
    .recharts-tooltip-item-list {
      text-wrap: wrap;
    }
  }
}
