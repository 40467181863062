.tile {
  background-color: white;
  height: 436px;
  width: 100%;
  border: 1px solid #c0bfc0;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  .tile-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;
    .lm-Heading {
      position: relative;
      margin: 0;
    }
    a {
      white-space: nowrap;
    }
  }
}
.card-row-bl {
  .row.column-splitter > div {
    padding: 0 7.5px;
  }
  .row.column-splitter > div:first-child {
    padding-left: 0 !important;
  }
  .row.column-splitter > div:last-child {
    padding-right: 0 !important;
  }
  padding-top: 15px;
}

myfavoritestile,
instantincentivestile {
  width: 100%;
}

myfavoritestile .favorites-link {
  max-width: 100%;
}
