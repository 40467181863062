.mock-wrapper {
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 10px;
  margin-bottom: 5px;

  .container {
    width: 100% !important;

    .row {
      margin: 0px !important;
    }
  }

  .mock-toggle-col {
    min-width: 125px;
  }

  .mock-input-col {
    min-width: 230px;
  }

  .mock-input-button-col {
    min-width: 175px;
  }

  .search-stat-input {
    width: 100%;
  }

  .lmig-Button {
    margin: 0px !important;
    min-width: 120px;
  }

  .lm-FieldGroup-field--input {
    margin: 0px !important;
  }

  .agentid-mock {
    width: 100%;
  }

  .no-click {
    cursor: 'default';

    &:hover {
      background-color: #fff !important;
    }
  }

  @media only screen and (max-width: 959px) {
    .search-input-suggestion-wrapper {
      inset: 0px !important;
    }
  }

  @media only screen and (max-width: 479px) {
    .search-input-suggestion-wrapper {
      inset: 0px !important;
    }
  }
}

.slider-panel {
  .slider-show {
    display: inherit !important;
  }

  .mock-toggle-col,
  .mock-input-col,
  .mock-input-button-col,
  .lmig-Button {
    width: 100%;
    max-width: 100%;
    flex: none;
  }
}
