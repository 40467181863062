keycontactspanel {
  width: 100%;
}

.key-contact {
  padding-bottom: 15px !important;
  text-align: left;
}

.key-contact-role {
  font-size: 1.3rem;
}

.key-contact-row {
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
  svg {
    margin-right: 1em;
    margin-top: 5px;
  }
}

.linkwrap {
  overflow-wrap: anywhere;
}

.key-contact-breadcrumb {
  display: flex;
  align-items: center;
  padding-bottom: 2em;
  svg {
    margin-right: 10px;
  }
  &:hover {
    cursor: pointer;
  }
}

.key-contact-initials-circle {
  font-size: 24px;
  font-weight: 300;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #fff;
  border: 1px solid #000;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  box-sizing: content-box;
  min-width: 34px;
}

.key-contacts-spinner {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
}

.key-contact-label {
  color: #919191;
}

.key-contact-phone-row {
  display: flex;
  .lm-Icon-Phone {
    margin-right: 1em;
    margin-top: 1.3em;
  }
  .key-contact-row:not(:last-child) {
    padding-bottom: 0;
  }
}
.key-contact-name-row {
  // display:flex; //uncomment to put name and icon on same line
  .key-contact-initials-circle {
    margin-right: 24px;
  }
  .lm-Heading:first-of-type {
    margin-bottom: 0.5em;
  }
}

.full-page-view-link {
  display: none !important;
}
.slider-panel .full-page-view-link {
  display: flex !important;
}
.key-contact-breadcrumb {
  padding-top: 2em;
}
.slider-panel .key-contact-breadcrumb {
  padding-top: 0em;
}
