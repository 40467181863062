.billing-account-select-modal {
  .lmig-Modal-body {
    margin-top: 20px !important;
  }
  .lmig-Modal-footer button {
    margin-top: 20px;
  }
  table tr td:first-child {
    width: 180px;
  }
}
