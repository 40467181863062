/// Creates styles for vertical nav when closed, as well as open.
@mixin lmig-verticalStyles {
  background: var(--lmig-FloatingHeader-vertical-menu-background-color);
  backdrop-filter: saturate(180%) blur(10px);
  display: none;
  transition:
    box-shadow lmig-interaction('exit'),
    color lmig-interaction('exit');

  &.lmig-FloatingHeader-nav-vertical--isOpen {
    display: block;
    margin: 0;
    padding-bottom: $lmig-spacing-default;
    overflow: hidden;
    position: absolute;
    top: calc(100% + 1px);
    width: 100%;
    max-height: calc(100vh - (var(--lmig-FloatingHeader-height) + (calc(var(--lmig-FloatingHeader-margin-top) * 3))));
    border-radius: 0 0 24px 24px;
    box-shadow: var(--lmig-FloatingHeader-box-shadow);

    .lmig-FloatingHeader-nav-menu {
      overflow-y: auto;
      max-height: inherit;
    }
  }

  &--scrolled {
    .lmig-FloatingHeader-nav-menu {
      box-shadow:
        inset 0 8px 12px -6px rgba(0, 0, 0, 0.04),
        inset 0 6px 4px -1px rgba(0, 0, 0, 0.04),
        inset 0 4px 6px -2px rgba(0, 0, 0, 0.08);
    }
  }

  .lmig-FloatingHeader-nav-menu {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

///  Mixin to create the vertical nav styling at each breakpoint
@mixin lmig-FloatingHeader-navVertical() {
  &-vertical {
    @include lmig-verticalStyles;
  }
}

/// Creates the modifiers to use for the inner content of the FloatingHeader when the vertical nav is open.
@mixin lmig-FloatingHeader-inner-vertical-open() {
  &--overlayIsOpen {
    border-radius: 24px 24px 0 0;
  }
}
