@use 'sass:color';
@use 'sass:list';

// shadows defined in Figma.
$lmig-FloatingHeader-box-shadow--specs: (0 1px 0, 0 4px 3px -2px, 0 6px 16px, 0 8px 8px);

// alphas defined within Figma.
$lmig-FloatingHeader-box-shadow--alpha: 0.24, 0.08, 0.16;

// This function will produce the default box-shadow, which is branded for Liberty.
@function lmig-floating-header-box-shadow-liberty() {
  $color-one: $lmig-color-libertyYellow;
  $color-two: color.change($lmig-color-libertyYellow, $alpha: list.nth($lmig-FloatingHeader-box-shadow--alpha, 1));
  $color-three: color.change($lmig-color-libertyYellow, $alpha: list.nth($lmig-FloatingHeader-box-shadow--alpha, 2));
  $color-four: color.change($lmig-color-libertyYellow, $alpha: list.nth($lmig-FloatingHeader-box-shadow--alpha, 3));

  @return #{list.nth($lmig-FloatingHeader-box-shadow--specs, 1)} #{$color-one},
    #{list.nth($lmig-FloatingHeader-box-shadow--specs, 2)} #{$color-two},
    #{list.nth($lmig-FloatingHeader-box-shadow--specs, 3)} #{$color-three},
    #{list.nth($lmig-FloatingHeader-box-shadow--specs, 4)} #{$color-four};
}

// This will produce the alternate box-shadow, which is branded to Safeco.

@function lmig-floating-header-box-shadow-alt() {
  $color-one: $lmig-color-palette-darkMode-gray-3;
  $color-two: color.change(
    $lmig-color-palette-darkMode-gray-4,
    $alpha: list.nth($lmig-FloatingHeader-box-shadow--alpha, 1)
  );
  $color-three: color.change(
    $lmig-color-palette-darkMode-gray-4,
    $alpha: list.nth($lmig-FloatingHeader-box-shadow--alpha, 2)
  );
  $color-four: color.change(
    $lmig-color-palette-darkMode-gray-4,
    $alpha: list.nth($lmig-FloatingHeader-box-shadow--alpha, 3)
  );

  @return #{list.nth($lmig-FloatingHeader-box-shadow--specs, 1)} #{$color-one},
    #{list.nth($lmig-FloatingHeader-box-shadow--specs, 2)} #{$color-two},
    #{list.nth($lmig-FloatingHeader-box-shadow--specs, 3)} #{$color-three},
    #{list.nth($lmig-FloatingHeader-box-shadow--specs, 4)} #{$color-four};
}

@include lmig-package-theme-css-variables('.lmig-FloatingHeader') {
  --lmig-FloatingHeader-brand-color: #{$lmig-color-palette-darkMode-gray-8};
  --lmig-FloatingHeader-background-color: #{$lmig-color-palette-darkMode-gray-8};
  --lmig-FloatingHeader-font-color: #{$lmig-color-palette-darkMode-gray-1};
  --lmig-FloatingHeader-box-shadow: #{lmig-floating-header-box-shadow-liberty()};
  --lmig-FloatingHeader-vertical-menu-background-color: #{$lmig-color-palette-darkMode-gray-8};
  --lmig-FloatingHeader-item--active-background-color: #{$lmig-color-white};

  &--safeco {
    // local shadow colors
    --lmig-FloatingHeader-brand-color: unset;
    --lmig-FloatingHeader-box-shadow: #{lmig-floating-header-box-shadow-alt()};
  }

  &-appName {
    &-pipe {
      display: none;
    }
  }

  &--bg-full {
    &.lmig-FloatingHeader--safeco {
      --lmig-FloatingHeader-font-color: #{$lmig-color-palette-darkMode-gray-1};

      .lmig-FloatingHeader-nav-menu {
        .lmig-FloatingHeader-item {
          .lm-Link {
            --lmig-Link-font-color: #{$lmig-color-palette-darkMode-gray-2};
          }
        }
      }

      .lmig-FloatingHeader-actions-menu {
        .lm-IconButton {
          --lmig-IconButton-font-color: #{$lmig-color-palette-darkMode-gray-2};
          --lmig-IconButton-icon-fill-color: #{$lmig-color-palette-darkMode-gray-2};

          .lm-IconWrapper {
            .lm-Icon-label {
              color: var(--lmig-IconButton-font-color);
            }
          }

          .lm-Icon {
            path {
              fill: var(--lmig-IconButton-icon-fill-color);
            }
          }
        }
      }
    }
  }

  &-actions {
    &-menu {
      .lm-IconButton {
        --lmig-IconButton-font-color: #{$lmig-color-palette-darkMode-gray-2};

        .lm-IconWrapper {
          .lm-Icon-label {
            color: var(--lmig-IconButton-font-color);
          }
        }
      }
    }
  }

  .lmig-FloatingHeader-nav-menu {
    .lmig-FloatingHeader-item {
      .lm-Link {
        --lmig-Link-font-color: #{$lmig-color-palette-darkMode-gray-2};
      }
    }
  }
}

@include lmig-package-theme-css-variables('.lm-Header') {
  &.has-FloatingHeader-nav .lm-Masthead {
    .lmig-FloatingHeader-nav {
      .lmig-FloatingHeader-nav-menu {
        .lmig-FloatingHeader-item {
          .lm-Link {
            --lmig-Link-font-color: #{$lmig-color-palette-darkMode-gray-2};
          }
        }
      }
    }
  }
}
