@import 'Styles/GlobalVariables';

.slider,
slider {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 80px;
  max-width: max-content;
  overflow: hidden;
  margin-left: 15px;

  @keyframes openAnimation {
    from {
      transform: translateX(-100%);
      display: none;
    }

    to {
      transform: translateX(0);
      display: flex;
    }
  }

  @keyframes openAnimationControls {
    0% {
      transform: translateX(-300%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .slider-controls {
    position: absolute;
    margin: 16px 0px;
    z-index: 100;
    display: flex;

    svg:hover {
      cursor: pointer;
    }

    .icon-circle {
      background-color: #919191;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        z-index: 5;
      }

      &:after {
        content: '';
        background-color: white;
        width: 28px;
        height: 28px;
        position: absolute;
        border-radius: 50%;
      }

      &:first-child {
        margin-right: 12px;
      }
    }
  }

  .slider-tabs.open + .slider-panels .slider-controls {
    right: 24px;
  }

  .slider-tabs.closed + .slider-panels .slider-controls {
    right: 310px;
  }

  .slider-tabs {
    z-index: 130;
    background-color: white;
    width: 80px;
    border-right: 1px solid $bg-medium-gray;

    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }

  .slider-panels {
    display: flex;
    z-index: 105;
    left: 110px;
    height: 100%;

    &.pinned {
      position: relative;
      left: 0px;
    }
  }

  .slider-panel-tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;

    &:last-child {
      padding-bottom: 3em;
    }

    .slider-panel-badge {
      margin-top: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      border-right: 4px solid white;
    }

    &:hover {
      cursor: pointer;
    }

    span {
      text-align: center;
      line-height: normal;
      padding: 8px 5px 0 5px;
    }
  }

  .slider-panel-text {
    font-size: 12px;
  }

  .slider-panel-tab.active-tab {
    .slider-panel-badge {
      border-right: 5px solid $font-link-blue;

      .slider-panel-text {
        color: $font-link-blue;
        font-weight: bold;
      }

      svg,
      path,
      polygon {
        fill: $font-link-blue;
      }
    }
  }

  .slider-panel-tab.new-content {
    &:before {
      content: '';
      display: inherit;
      width: 10px;
      height: 10px;
      background-color: #d32f2f;
      border-radius: 50%;
      position: absolute;
      right: 23px;
      top: 19px;
    }
  }

  .no-transition .slider-panel {
    animation: none !important;
  }

  .slider-panel {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 310px;
    padding: 64px 24px 24px 24px;
    height: 100%;
    left: 110px;
    background-color: white;
    border-right: 1px solid #919191;

    &.open {
      left: 0px;
      animation: openAnimation 0.3s forwards;
    }

    &.closed {
      left: -310px;
      display: none;
    }

    h4 {
      margin-top: 0;
    }

    > div:not(:only-child):not(:last-child) {
      border-bottom: 1px solid $bg-medium-gray;
      padding-bottom: 32px;
    }

    > div:nth-child(n + 3) {
      margin-top: 32px;
    }
  }

  .slider-panel-link-box {
    background-color: white;
    margin-bottom: 16px;
    width: 100%;

    ul {
      padding-left: 0px;
      margin-bottom: 0px;
    }

    li {
      list-style: none;
      padding: 5px 0px;
    }

    a {
      font-size: 16px;
    }

    .navigation-section {
      .no-favorites {
        margin-bottom: 15px;
      }
    }

    .empty-nav-section {
      display: none;
    }

    .empty-nav-section:only-child {
      padding-top: 20px;
      display: block;
      font-size: 16px;
      color: red;
    }
  }
}

.favorites-section {
  h5 {
    font-weight: 500;
  }

  .menu-section-links {
    padding-bottom: 24px;
    padding-top: 8px;

    &:empty {
      display: none;
    }
  }
}

.favorites-section .menu-section-links .menu-section-link,
.slider-panel-link-box .menu-section-link {
  padding-bottom: 16px;

  p {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}

.slider-panel-link-box .lmig-Accordion-list-listItem-header-button,
.lmig-Accordion-content {
  padding-left: 0;
  padding-right: 0;
}

@for $i from 0 through 15 {
  .hide-accordion-#{$i} .lmig-Accordion-list-listItem:nth-child(#{$i + 1}) {
    display: none;
  }
}

.favorites-page .empty-nav-section {
  padding-top: 24px;
}

.nav-section-rich-text-top {
  padding-bottom: 24px;
}

.menu-section-link-subtext {
  padding-left: 24px;
}

.open {
  .slider-panels {
    display: contents !important;

    .slider-controls {
      animation: openAnimationControls 0.5s ease-out;
    }
  }
}

.mobile-slider-close-icon {
  display: none;
}

@media only screen and (max-width: 1081px) {
  .slider-panel-text {
    font-size: 9px !important;
  }

  .mobile-slider-close-icon {
    all: unset;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .slider {
    z-index: 15;
    height: 92px;
    width: 100% !important;
    position: fixed !important;
    inset: revert !important;
    bottom: 0 !important;
    border-top: 1px solid #e5e5e5;
    left: 0;
    right: 0;
    max-width: none;
  }

  .slider.open {
    z-index: 1000;
  }

  .slider-tabs {
    width: 100% !important;
    padding: 0 15px;
    display: grid !important;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
  }

  .slider-panel-tab {
    .slider-panel-badge {
      border-right: 0 !important;
    }

    .slider-panel-badge::after {
      content: '';
      background-color: none;
      height: 5px;
      width: 0;
      position: absolute;
      top: 2px;
      transition: 0.5s ease-in-out;
    }
  }

  .slider-panel-tab.active-tab {
    .slider-panel-badge {
      transition: cubic-bezier(0.075, 0.82, 0.165, 1);

      .slider-panel-text {
        font-weight: normal;
      }
    }

    .slider-panel-badge::after {
      content: '';
      background-color: $font-link-blue;
      height: 5px;
      width: 50%;
      position: absolute;
      top: 2px;
    }
  }

  @keyframes mobileSliderAnimation {
    from {
      transform: translateY(200%);
      display: none;
    }

    to {
      transform: translateY(0);
      display: flex;
    }
  }

  .slider-panel {
    transition: transform 0.3s;
    -webkit-transition: transform 0.3s;

    &.open {
      position: fixed;
      bottom: 92px;
      top: 0px;
      width: 100%;
      height: auto;
      overflow: auto;
      animation: mobileSliderAnimation 0.3s forwards !important;
    }

    &.closed {
      left: 0 !important;
      transform: translateY(200%);
    }
  }
}

@keyframes mobileMoreTrayAnimation {
  from {
    transform: translateY(200%);
    display: none;
  }

  to {
    transform: translateY(-80px);
    display: flex;
  }
}

#mobile-slider-tabs-tray {
  position: fixed;
  width: 93%;
  left: 0;
  height: 80px;
  overflow: visible;
  z-index: 120;
  background: white;
  animation: mobileMoreTrayAnimation 0.5s forwards !important;
  border-left: 1px solid $bg-medium-gray;
  border-right: 1px solid $bg-medium-gray;
  border-bottom: 1px solid $bg-medium-gray;
  margin: 0px 3.5%;
}

#mobile-slider-tabs-tray-overlay {
  z-index: -1;
  background: white;
  position: fixed;
  width: 100%;
  height: 100%;
  border: none;
  border-top: 1px solid $bg-medium-gray;
  border-radius: 20px;
  transform: translateY(-15px);
}

#mobile-slider-tabs-tray-content {
  position: fixed;
  height: 85%;
  width: 100%;
  z-index: 1;
  padding: 0 15px;
  display: grid;
  transform: translateY(-14px);
}

#mobile-slider-tabs-tray-content > .slider-panel-tab > .slider-panel-badge > span > svg {
  width: 24px !important;
  height: 24px !important;
}

.more-button {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.more-button.active {
  color: $font-link-blue;

  svg,
  path,
  polygon {
    fill: $font-link-blue;
  }
}

.more-button::after {
  content: '';
  background-color: none;
  height: 5px;
  width: 0;
  position: absolute;
  top: 2px;
  transition: 0.5s ease-in-out;
}

.more-button.active::after {
  content: '';
  background-color: $font-link-blue;
  height: 5px;
  width: 50%;
  position: absolute;
  top: 2px;
}
